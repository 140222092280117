import { HttpClient } from '@angular/common/http';
import { getMetadataHeaders } from '../http-auth/headers.helper';
var CommonEntitiesService = /** @class */ /*@__PURE__*/ (function () {
    function CommonEntitiesService(httpClient) {
        this.httpClient = httpClient;
    }
    CommonEntitiesService.prototype.getVirtualPointsActive = function (date) {
        return this.httpClient.get("/eepoints/virtual/active", {
            headers: getMetadataHeaders({ auth: true, cache: true, cacheMaxAge: 7200000 }),
            params: { timeFrom: date.toString() }
        });
    };
    CommonEntitiesService.prototype.loadOperators = function () {
        return this.httpClient.get('/operators', { headers: getMetadataHeaders({ auth: true }) });
    };
    return CommonEntitiesService;
}());
export { CommonEntitiesService };
