import { TrialExpiredComponent } from './trial-expired.component';

export const trialExpiredModalConfig = {
  title: 'Your free trial has expired',
  component: {
    name: TrialExpiredComponent,
    options: {}
  },
  data: {
    width: 870
  }
};
