import { Router } from '@angular/router';
import { ModalService } from 'appy-gas-core';
import { AuthenticationService } from '../../../core/http-auth/authentication.service';
var UpdateProfileComponent = /** @class */ /*@__PURE__*/ (function () {
    function UpdateProfileComponent(authenticationService, modalService, router) {
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.router = router;
    }
    UpdateProfileComponent.prototype.closeModal = function () {
        this.modalService.hide();
    };
    UpdateProfileComponent.prototype.goToProfile = function () {
        this.closeModal();
        this.router.navigate(['/profile']);
    };
    UpdateProfileComponent.prototype.goToHomePage = function () {
        var _this = this;
        this.closeModal();
        this.authenticationService.logout().subscribe(function () {
            _this.router.navigate(['/dashboard']);
        });
    };
    return UpdateProfileComponent;
}());
export { UpdateProfileComponent };
