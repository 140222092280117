import { SwitchToExclusiveInfoComponent } from './switch-to-exclusive-info.component';

export const switchToExclusiveInfoModalConfig = {
  title: 'This feature is available for exclusive users only',
  component: {
    name: SwitchToExclusiveInfoComponent,
    options: {}
  },
  data: {
    width: 670
  }
};
