import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';
import * as i0 from "@angular/core";
var FilterHelperService = /** @class */ /*@__PURE__*/ (function () {
    function FilterHelperService() {
    }
    FilterHelperService.getEventPointsFilter = function (physicalPoints) {
        var mappedIP = physicalPoints.map(function (item) {
            var pointId = item.pointId;
            return {
                name: item.pointName + " (" + item.operatorName + ")",
                pointName: "" + item.pointName,
                value: pointId,
                tsoId: [item.operatorId],
                selected: true
            };
        });
        var mappedTSO = physicalPoints.map(function (item) {
            return {
                name: item.operatorName,
                value: item.operatorId,
                selected: true
            };
        });
        return this.getUniqFilter(mappedIP, mappedTSO);
    };
    FilterHelperService.getMyPointsWidgetFilter = function (physicalPoints) {
        var mappedIP = physicalPoints.map(function (item) {
            var operatorNames = item.operators.map(function (operator) { return operator.name; });
            var operatorIds = item.operators.map(function (operator) { return operator.operatorId; });
            return {
                name: item.pointName + " (" + operatorNames.join(', ') + ")",
                pointName: "" + item.pointName,
                value: item.eePointId,
                tsoId: operatorIds,
                selected: true
            };
        });
        var mappedTSO = physicalPoints.map(function (item) {
            return item.operators.map(function (operator) {
                return ({
                    name: operator.name,
                    value: operator.operatorId,
                    selected: true
                });
            });
        });
        return this.getUniqFilter(mappedIP, flatten(mappedTSO));
    };
    FilterHelperService.getFilteredSelectedEventPoints = function (selectedPoints, selectedPointsAppliedFilter) {
        if (selectedPointsAppliedFilter) {
            var anySelectedFilter_1 = selectedPointsAppliedFilter.ip.filter(function (IPFilterItem) { return IPFilterItem.selected; });
            if (anySelectedFilter_1.length) {
                return selectedPoints.filter(function (event) {
                    return anySelectedFilter_1.some(function (filterItem) {
                        return filterItem.pointName === event.pointName && filterItem.tsoId.includes(event.operatorId);
                    });
                });
            }
            else {
                return [];
            }
        }
        else {
            return selectedPoints;
        }
    };
    FilterHelperService.getFilteredSelectedMyPointsWidget = function (selectedPoints, selectedPointsAppliedFilter) {
        if (selectedPointsAppliedFilter) {
            var selectedFilters_1 = selectedPointsAppliedFilter.ip.filter(function (IPFilterItem) { return IPFilterItem.selected; });
            if (selectedFilters_1.length) {
                return selectedPoints.filter(function (point) {
                    return selectedFilters_1.some(function (filterItem) { return filterItem.pointName === point.pointName; });
                });
            }
            else {
                return [];
            }
        }
        else {
            return selectedPoints;
        }
    };
    FilterHelperService.getUniqFilter = function (IPs, TSOs) {
        var ip = uniqWith(IPs, isEqual);
        var tso = uniqWith(TSOs, isEqual);
        return { ip: sortBy(ip, ['pointName']), tso: sortBy(tso, ['name']), searchQuery: '' };
    };
    FilterHelperService.ɵprov = i0.ɵɵdefineInjectable({ factory: function FilterHelperService_Factory() { return new FilterHelperService(); }, token: FilterHelperService, providedIn: "root" });
    return FilterHelperService;
}());
export { FilterHelperService };
