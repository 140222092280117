import { SwitchDeclinedComponent } from './switch-declined.component';

export const switchDeclinedModalConfig = {
  title: 'This feature is available for exclusive users only',
  component: {
    name: SwitchDeclinedComponent,
    options: {}
  },
  data: {
    width: 462
  }
};
