import { ModalService } from 'appy-gas-core';
import { LocalStorageKeysEnum } from '../../../enums/local-storage-keys.enum';
var YouAreExclusiveUserComponent = /** @class */ /*@__PURE__*/ (function () {
    function YouAreExclusiveUserComponent(modalService) {
        this.modalService = modalService;
    }
    YouAreExclusiveUserComponent.prototype.hideModal = function () {
        localStorage.removeItem(LocalStorageKeysEnum.USER_ROLE_EXCLUSIVE_MODAL);
        this.modalService.hide();
    };
    return YouAreExclusiveUserComponent;
}());
export { YouAreExclusiveUserComponent };
