import each from 'lodash/each';
import { finalize } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { activityName, emailValidator, ModalService, nameValidator, passwordConfirmationValidator, passwordValidator } from 'appy-gas-core';
import { AuthenticationService } from '../../core/http-auth/authentication.service';
import { AuthModalHolderService } from '../auth-modal-holder.service';
import { AuthModalType } from '../auth-modal-type.enum';
import { userDeactivatedModalConfig } from '../auth-modals';
var SuggestionType = /*@__PURE__*/ (function (SuggestionType) {
    SuggestionType["ACTIVITY"] = "activity";
    return SuggestionType;
})({});
var SignupComponent = /** @class */ /*@__PURE__*/ (function () {
    function SignupComponent(router, formBuilder, modalService, authenticationService, injector, modalHolder) {
        var _a;
        this.router = router;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.authenticationService = authenticationService;
        this.injector = injector;
        this.modalHolder = modalHolder;
        this.isPage = true;
        this.error = null;
        this.isLoading = false;
        this.title = 'Start navigating the European gas market with appygas';
        this.description = 'Complete your account setup';
        this.emailWasSent = false;
        this.suggestionType = SuggestionType;
        this.suggestions = (_a = {},
            _a[SuggestionType.ACTIVITY] = [],
            _a);
        this.createForm();
    }
    SignupComponent.searchOptions = function (options) {
        return Array.from(options, function (map) {
            return { id: map[0], label: map[1] };
        });
    };
    SignupComponent.prototype.signup = function () {
        var _this = this;
        var _a;
        if (!this.signupForm.valid) {
            each(this.signupForm.controls, function (control) { return control.markAsTouched(); });
            return;
        }
        this.isLoading = true;
        this.signupForm.value.activityId = ((_a = this.signupForm.value.activity) === null || _a === void 0 ? void 0 : _a.id) || '';
        this.authenticationService
            .signup(this.signupForm.value)
            .pipe(finalize(function () {
            _this.signupForm.markAsPristine();
            _this.isLoading = false;
        }))
            .subscribe(function () {
            window.location.href = '/auth/signup/success?email=' + _this.signupForm.controls.email.value;
            // this.router.navigate(['/auth/signup/success'], { queryParams: { email: this.signupForm.controls.email.value} })
            // .then(() => {
            //   window.location.href = '/auth/signup/success';
            //   // window.location.reload();
            // });
            //   this.title = 'Check your email!';
            //   this.description = `We’ve sent a message to <b>${this.signupForm.controls.email.value}</b>.
            //  Open it and follow the instruction.<br><br>If you have a Promo Code do not forget to use it in the Profile section.`;
            //   this.emailWasSent = true;
        }, function (error) {
            if (error.status === 400) {
                _this.error = error.error;
            }
            else if (error.status === 410) {
                _this.modalService.show(_this.injector, userDeactivatedModalConfig);
            }
            else {
                _this.error = error.json();
            }
        });
    };
    SignupComponent.prototype.signIn = function () {
        if (this.isPage) {
            this.router.navigate(['/auth/login']);
        }
        else {
            this.modalHolder.put(AuthModalType.LOGIN);
        }
    };
    SignupComponent.prototype.closeModal = function () {
        if (!this.isPage) {
            this.modalService.hide();
        }
    };
    SignupComponent.prototype.suggest = function (event, key) {
        this.suggestions[key] = SignupComponent.searchOptions(activityName);
    };
    SignupComponent.prototype.createForm = function () {
        this.signupForm = this.formBuilder.group({
            firstname: ['', [Validators.required, nameValidator]],
            lastname: ['', [Validators.required, nameValidator]],
            email: ['', [Validators.required, emailValidator]],
            password: ['', [Validators.required, passwordValidator]],
            confirmPassword: ['', [Validators.required, passwordConfirmationValidator]],
            activity: [''],
            phone: [''],
            newsLetter: [true]
        });
    };
    return SignupComponent;
}());
export { SignupComponent };
