export var IpPhysicalPointVariablesEnum = /*@__PURE__*/ (function (IpPhysicalPointVariablesEnum) {
    IpPhysicalPointVariablesEnum[IpPhysicalPointVariablesEnum["PHYSICAL_FLOW"] = 114] = "PHYSICAL_FLOW";
    IpPhysicalPointVariablesEnum[IpPhysicalPointVariablesEnum["CLEAN_NOM_ENTRY"] = 25] = "CLEAN_NOM_ENTRY";
    IpPhysicalPointVariablesEnum[IpPhysicalPointVariablesEnum["CLEAN_NOM_EXIT"] = 26] = "CLEAN_NOM_EXIT";
    IpPhysicalPointVariablesEnum[IpPhysicalPointVariablesEnum["TECHNICAL_ENTRY"] = 31] = "TECHNICAL_ENTRY";
    IpPhysicalPointVariablesEnum[IpPhysicalPointVariablesEnum["TECHNICAL_EXIT"] = 32] = "TECHNICAL_EXIT";
    IpPhysicalPointVariablesEnum[IpPhysicalPointVariablesEnum["BOOKED_ALL_FIRM_CAPACITY_ENTRY"] = 112] = "BOOKED_ALL_FIRM_CAPACITY_ENTRY";
    IpPhysicalPointVariablesEnum[IpPhysicalPointVariablesEnum["BOOKED_ALL_FIRM_CAPACITY_EXIT"] = 113] = "BOOKED_ALL_FIRM_CAPACITY_EXIT";
    return IpPhysicalPointVariablesEnum;
})({});
