import { map } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ModalService, User } from 'appy-gas-core';
import { updateProfileModalConfig } from '../../auth';
import { AuthenticationService } from './authentication.service';
var REQUIRED_PROPERTIES_CONFIG = ['firstName', 'lastName', 'email'];
var EmptyPropGuard = /** @class */ /*@__PURE__*/ (function () {
    function EmptyPropGuard(authenticationService, modalService, injector, 
    // tslint:disable-next-line:typedef
    envVariables) {
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.injector = injector;
        this.envVariables = envVariables;
    }
    EmptyPropGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        if (state.url.startsWith('/profile')) {
            return true;
        }
        return this.authenticationService.getUser().pipe(map(function (user) {
            if (user && _this.isRequiredPropEmpty(user) && _this.isUserRegisterBeforeRelease(user)) {
                _this.modalService.show(_this.injector, updateProfileModalConfig);
                return false;
            }
            else {
                return true;
            }
        }));
    };
    EmptyPropGuard.prototype.isUserRegisterBeforeRelease = function (user) {
        return user.registrationDate <= this.envVariables.userManagementReleaseDate.getTime();
    };
    EmptyPropGuard.prototype.isRequiredPropEmpty = function (user) {
        return REQUIRED_PROPERTIES_CONFIG.some(function (key) { return user[key] === null || user[key] === ''; });
    };
    return EmptyPropGuard;
}());
export { EmptyPropGuard };
