var GetEePoints = /** @class */ /*@__PURE__*/ (function () {
    function GetEePoints(eePoints) {
        this.eePoints = eePoints;
    }
    GetEePoints.type = '[Common Entities] Get EE Points';
    return GetEePoints;
}());
export { GetEePoints };
var GetOperators = /** @class */ /*@__PURE__*/ (function () {
    function GetOperators(operators) {
        this.operators = operators;
    }
    GetOperators.type = '[Common Entities] Get Operators';
    return GetOperators;
}());
export { GetOperators };
