import { __assign, __decorate, __metadata } from "tslib";
import { Action, State } from '@ngxs/store';
import { DeleteFullSpreadFilter, GetAllFilterSpreads, GetAllFilterSpreadsFailed, GetAllFilterSpreadsLoaded, GetAllFilterSpreadsLoading, GetBlockPositions, GetFullSpreadFilter, GetFullSpreadFilterFailed, GetFullSpreadFilterLoaded, GetFullSpreadFilterLoading, GetHubPrices, GetHubPricesFailed, GetHubPricesLoaded, GetHubPricesLoading, GetLastAuctionStats, GetLastAuctionStatsFailed, GetLastAuctionStatsLoaded, GetLastAuctionStatsLoading, GetLngCountries, GetLngCountriesFailed, GetLngCountriesLoaded, GetLngCountriesLoading, GetMaintenancePlan, GetMaintenancePlanFailed, GetMaintenancePlanLoaded, GetMaintenancePlanLoading, GetMaintenanceUpdate, GetMaintenanceUpdateFailed, GetMaintenanceUpdateSuccess, GetPipelineCountries, GetPipelineCountriesFailed, GetPipelineCountriesLoaded, GetPipelineCountriesLoading, GetPipelineCountriesVsLngSupply, GetPipelineCountriesVsLngSupplyFailed, GetPipelineCountriesVsLngSupplyLoaded, GetPipelineCountriesVsLngSupplyLoading, GetPreviewSpreads, GetPreviewSpreadsFailed, GetPreviewSpreadsLoaded, GetPreviewSpreadsLoading, GetSpreadFilters, GetSpreadFiltersFailed, GetSpreadFiltersLoaded, GetSpreadFiltersLoading, GetSpreadMarketAreas, GetSpreads, GetSpreadsFailed, GetSpreadsLoaded, GetSpreadsLoading } from './dashboard.actions';
var ɵ0 = undefined, ɵ1 = undefined, ɵ2 = undefined, ɵ3 = undefined, ɵ4 = undefined, ɵ5 = undefined, ɵ6 = undefined, ɵ7 = undefined, ɵ8 = undefined, ɵ9 = undefined, ɵ10 = undefined, ɵ11 = undefined, ɵ12 = undefined;
var DashboardState = /** @class */ /*@__PURE__*/ (function () {
    function DashboardState() {
    }
    DashboardState.prototype.getHubPrices = function (ctx, _a) {
        var hubPrices = _a.hubPrices;
        var state = ctx.getState();
        ctx.patchState({
            hubPrices: __assign(__assign({}, state.hubPrices), { data: hubPrices })
        });
    };
    DashboardState.prototype.getHubPricesFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            hubPrices: __assign(__assign({}, state.hubPrices), { loaded: false, loading: false })
        });
    };
    DashboardState.prototype.getHubPricesLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            hubPrices: __assign(__assign({}, state.hubPrices), { loaded: false, loading: true })
        });
    };
    DashboardState.prototype.getHubPricesLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            hubPrices: __assign(__assign({}, state.hubPrices), { loaded: true, loading: false })
        });
    };
    DashboardState.prototype.getLngCountries = function (ctx, _a) {
        var lngCountries = _a.lngCountries;
        var state = ctx.getState();
        ctx.patchState({
            lngCountries: __assign(__assign({}, state.lngCountries), { data: lngCountries })
        });
    };
    DashboardState.prototype.getLngCountriesFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            lngCountries: __assign(__assign({}, state.lngCountries), { loaded: false, loading: false })
        });
    };
    DashboardState.prototype.getLngCountriesLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            lngCountries: __assign(__assign({}, state.lngCountries), { loaded: false, loading: true })
        });
    };
    DashboardState.prototype.getLngCountriesLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            lngCountries: __assign(__assign({}, state.lngCountries), { loaded: true, loading: false })
        });
    };
    DashboardState.prototype.getPipelineCountries = function (ctx, _a) {
        var pipelineCountries = _a.pipelineCountries;
        var state = ctx.getState();
        ctx.patchState({
            pipelineCountries: __assign(__assign({}, state.pipelineCountries), { data: pipelineCountries })
        });
    };
    DashboardState.prototype.getPipelineCountriesFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            pipelineCountries: __assign(__assign({}, state.pipelineCountries), { loaded: false, loading: false })
        });
    };
    DashboardState.prototype.getPipelineCountriesLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            pipelineCountries: __assign(__assign({}, state.pipelineCountries), { loaded: false, loading: true })
        });
    };
    DashboardState.prototype.getPipelineCountriesLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            pipelineCountries: __assign(__assign({}, state.pipelineCountries), { loaded: true, loading: false })
        });
    };
    DashboardState.prototype.getPipelineCountriesVsLngSupply = function (ctx, _a) {
        var pipelineCountries = _a.pipelineCountries;
        var state = ctx.getState();
        ctx.patchState({
            pipelineCountriesVsLng: __assign(__assign({}, state.pipelineCountriesVsLng), { data: pipelineCountries })
        });
    };
    DashboardState.prototype.getPipelineCountriesVsLngSupplyFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            pipelineCountriesVsLng: __assign(__assign({}, state.pipelineCountriesVsLng), { loaded: false, loading: false })
        });
    };
    DashboardState.prototype.getPipelineCountriesVsLngSupplyLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            pipelineCountriesVsLng: __assign(__assign({}, state.pipelineCountriesVsLng), { loaded: false, loading: true })
        });
    };
    DashboardState.prototype.getPipelineCountriesVsLngSupplyLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            pipelineCountriesVsLng: __assign(__assign({}, state.pipelineCountriesVsLng), { loaded: true, loading: false })
        });
    };
    DashboardState.prototype.getMaintenancePlan = function (ctx, _a) {
        var maintenancePlan = _a.maintenancePlan;
        var state = ctx.getState();
        ctx.patchState({
            maintenancePlan: __assign(__assign({}, state.maintenancePlan), { data: maintenancePlan })
        });
    };
    DashboardState.prototype.getMaintenancePlanFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            maintenancePlan: __assign(__assign({}, state.maintenancePlan), { loaded: false, loading: false })
        });
    };
    DashboardState.prototype.getMaintenancePlanLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            maintenancePlan: __assign(__assign({}, state.maintenancePlan), { loaded: false, loading: true })
        });
    };
    DashboardState.prototype.getMaintenancePlanLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            maintenancePlan: __assign(__assign({}, state.maintenancePlan), { loaded: true, loading: false })
        });
    };
    DashboardState.prototype.getMaintenanceUpdate = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({ maintenanceUpdate: __assign(__assign({}, state.maintenanceUpdate), { loading: true }) });
    };
    DashboardState.prototype.getMaintenanceUpdateSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var maintenanceUpdate = _b.maintenanceUpdate;
        patchState({ maintenanceUpdate: { data: maintenanceUpdate, loaded: true, loading: false } });
    };
    DashboardState.prototype.getMaintenanceUpdateFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            maintenancePlan: __assign(__assign({}, state.maintenancePlan), { loaded: false, loading: false })
        });
    };
    DashboardState.prototype.getLastAuctionStats = function (ctx, _a) {
        var lastAuctionStats = _a.lastAuctionStats;
        var state = ctx.getState();
        ctx.patchState({
            lastAuctionStats: __assign(__assign({}, state.lastAuctionStats), { data: lastAuctionStats })
        });
    };
    DashboardState.prototype.getLastAuctionStatsFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            lastAuctionStats: __assign(__assign({}, state.lastAuctionStats), { loaded: false, loading: false })
        });
    };
    DashboardState.prototype.getLastAuctionStatsLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            lastAuctionStats: __assign(__assign({}, state.lastAuctionStats), { loaded: false, loading: true })
        });
    };
    DashboardState.prototype.getLastAuctionStatsLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            lastAuctionStats: __assign(__assign({}, state.lastAuctionStats), { loaded: true, loading: false })
        });
    };
    DashboardState.prototype.getSpreads = function (ctx, _a) {
        var spreads = _a.spreads;
        var state = ctx.getState();
        ctx.patchState({
            spreads: __assign(__assign({}, state.spreads), { data: spreads }),
        });
    };
    DashboardState.prototype.getSpreadsFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreads: __assign(__assign({}, state.spreads), { loaded: false, loading: false }),
        });
    };
    DashboardState.prototype.getSpreadsLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreads: __assign(__assign({}, state.spreads), { loaded: false, loading: true }),
        });
    };
    DashboardState.prototype.getSpreadsLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreads: __assign(__assign({}, state.spreads), { loaded: true, loading: false }),
        });
    };
    DashboardState.prototype.getAllFilterSpreads = function (ctx, _a) {
        var allFilterSpreads = _a.allFilterSpreads;
        var state = ctx.getState();
        ctx.patchState({
            allFilterSpreads: __assign(__assign({}, state.spreads), { data: allFilterSpreads }),
        });
    };
    DashboardState.prototype.getAllFilterSpreadsFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            allFilterSpreads: __assign(__assign({}, state.allFilterSpreads), { loaded: false, loading: false }),
        });
    };
    DashboardState.prototype.getAllFilterSpreadsLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            allFilterSpreads: __assign(__assign({}, state.allFilterSpreads), { loaded: false, loading: true }),
        });
    };
    DashboardState.prototype.getAllFilterSpreadsLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            allFilterSpreads: __assign(__assign({}, state.allFilterSpreads), { loaded: true, loading: false }),
        });
    };
    DashboardState.prototype.getPreviewSpreads = function (ctx, _a) {
        var previewSpreads = _a.previewSpreads;
        var state = ctx.getState();
        ctx.patchState({
            previewSpreads: __assign(__assign({}, state.spreads), { data: previewSpreads }),
        });
    };
    DashboardState.prototype.getPreviewSpreadsFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            previewSpreads: __assign(__assign({}, state.previewSpreads), { loaded: false, loading: false }),
        });
    };
    DashboardState.prototype.getPreviewSpreadsLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            previewSpreads: __assign(__assign({}, state.previewSpreads), { loaded: false, loading: true }),
        });
    };
    DashboardState.prototype.getPreviewSpreadsLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            previewSpreads: __assign(__assign({}, state.previewSpreads), { loaded: true, loading: false }),
        });
    };
    DashboardState.prototype.getSreadFilters = function (ctx, _a) {
        var spreadFilters = _a.spreadFilters;
        var state = ctx.getState();
        ctx.patchState({
            spreadFilters: __assign(__assign({}, state.spreadFilters), { data: spreadFilters }),
        });
    };
    DashboardState.prototype.getSpreadFiltersFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreadFilters: __assign(__assign({}, state.spreadFilters), { loaded: false, loading: false }),
        });
    };
    DashboardState.prototype.getSpreadFiltersLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreadFilters: __assign(__assign({}, state.spreadFilters), { loaded: false, loading: true }),
        });
    };
    DashboardState.prototype.getSpreadFiltersLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreadFilters: __assign(__assign({}, state.spreadFilters), { loaded: true, loading: false }),
        });
    };
    DashboardState.prototype.getSpreadMarketAreas = function (ctx, _a) {
        var spreadMarketAreas = _a.spreadMarketAreas;
        var state = ctx.getState();
        ctx.patchState({
            spreadMarketAreas: __assign(__assign({}, state.spreadMarketAreas), { data: spreadMarketAreas }),
        });
    };
    DashboardState.prototype.getSpreadMarketAreasFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreadMarketAreas: __assign(__assign({}, state.spreadMarketAreas), { loaded: false, loading: false }),
        });
    };
    DashboardState.prototype.getSpreadMarketAreasLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreadMarketAreas: __assign(__assign({}, state.spreadMarketAreas), { loaded: false, loading: true }),
        });
    };
    DashboardState.prototype.getSpreadMarketAreasLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreadMarketAreas: __assign(__assign({}, state.spreadMarketAreas), { loaded: true, loading: false }),
        });
    };
    DashboardState.prototype.getFullSpreadFilter = function (ctx, _a) {
        var spreadFilterForEdit = _a.spreadFilterForEdit;
        var state = ctx.getState();
        ctx.patchState({
            spreadFilterForEdit: __assign(__assign({}, state.spreadFilterForEdit), { data: spreadFilterForEdit }),
        });
    };
    DashboardState.prototype.getFullSpreadFilterFailed = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreadFilterForEdit: __assign(__assign({}, state.spreadFilterForEdit), { loaded: false, loading: false }),
        });
    };
    DashboardState.prototype.getFullSpreadFilterLoading = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreadFilterForEdit: __assign(__assign({}, state.spreadFilterForEdit), { loaded: false, loading: true }),
        });
    };
    DashboardState.prototype.getFullSpreadFilterLoaded = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreadFilterForEdit: __assign(__assign({}, state.spreadFilterForEdit), { loaded: true, loading: false }),
        });
    };
    DashboardState.prototype.deleteFullSpreadFilter = function (ctx) {
        var state = ctx.getState();
        ctx.patchState({
            spreadFilterForEdit: __assign(__assign({}, state.spreadFilterForEdit), { data: undefined }),
        });
    };
    DashboardState.prototype.getBlockPositions = function (_a, _b) {
        var patchState = _a.patchState;
        var blockPositions = _b.blockPositions;
        patchState({ blockPositions: blockPositions });
    };
    __decorate([
        Action(GetHubPrices),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetHubPrices]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getHubPrices", null);
    __decorate([
        Action(GetHubPricesFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getHubPricesFailed", null);
    __decorate([
        Action(GetHubPricesLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getHubPricesLoading", null);
    __decorate([
        Action(GetHubPricesLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getHubPricesLoaded", null);
    __decorate([
        Action(GetLngCountries),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetLngCountries]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getLngCountries", null);
    __decorate([
        Action(GetLngCountriesFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getLngCountriesFailed", null);
    __decorate([
        Action(GetLngCountriesLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getLngCountriesLoading", null);
    __decorate([
        Action(GetLngCountriesLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getLngCountriesLoaded", null);
    __decorate([
        Action(GetPipelineCountries),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetPipelineCountries]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPipelineCountries", null);
    __decorate([
        Action(GetPipelineCountriesFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPipelineCountriesFailed", null);
    __decorate([
        Action(GetPipelineCountriesLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPipelineCountriesLoading", null);
    __decorate([
        Action(GetPipelineCountriesLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPipelineCountriesLoaded", null);
    __decorate([
        Action(GetPipelineCountriesVsLngSupply),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetPipelineCountriesVsLngSupply]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPipelineCountriesVsLngSupply", null);
    __decorate([
        Action(GetPipelineCountriesVsLngSupplyFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPipelineCountriesVsLngSupplyFailed", null);
    __decorate([
        Action(GetPipelineCountriesVsLngSupplyLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPipelineCountriesVsLngSupplyLoading", null);
    __decorate([
        Action(GetPipelineCountriesVsLngSupplyLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPipelineCountriesVsLngSupplyLoaded", null);
    __decorate([
        Action(GetMaintenancePlan),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetMaintenancePlan]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getMaintenancePlan", null);
    __decorate([
        Action(GetMaintenancePlanFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getMaintenancePlanFailed", null);
    __decorate([
        Action(GetMaintenancePlanLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getMaintenancePlanLoading", null);
    __decorate([
        Action(GetMaintenancePlanLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getMaintenancePlanLoaded", null);
    __decorate([
        Action(GetMaintenanceUpdate),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getMaintenanceUpdate", null);
    __decorate([
        Action(GetMaintenanceUpdateSuccess),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetMaintenanceUpdateSuccess]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getMaintenanceUpdateSuccess", null);
    __decorate([
        Action(GetMaintenanceUpdateFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getMaintenanceUpdateFailed", null);
    __decorate([
        Action(GetLastAuctionStats),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetLastAuctionStats]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getLastAuctionStats", null);
    __decorate([
        Action(GetLastAuctionStatsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getLastAuctionStatsFailed", null);
    __decorate([
        Action(GetLastAuctionStatsLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getLastAuctionStatsLoading", null);
    __decorate([
        Action(GetLastAuctionStatsLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getLastAuctionStatsLoaded", null);
    __decorate([
        Action(GetSpreads),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetSpreads]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSpreads", null);
    __decorate([
        Action(GetSpreadsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSpreadsFailed", null);
    __decorate([
        Action(GetSpreadsLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSpreadsLoading", null);
    __decorate([
        Action(GetSpreadsLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSpreadsLoaded", null);
    __decorate([
        Action(GetAllFilterSpreads),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetAllFilterSpreads]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getAllFilterSpreads", null);
    __decorate([
        Action(GetAllFilterSpreadsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getAllFilterSpreadsFailed", null);
    __decorate([
        Action(GetAllFilterSpreadsLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getAllFilterSpreadsLoading", null);
    __decorate([
        Action(GetAllFilterSpreadsLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getAllFilterSpreadsLoaded", null);
    __decorate([
        Action(GetPreviewSpreads),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetPreviewSpreads]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPreviewSpreads", null);
    __decorate([
        Action(GetPreviewSpreadsFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPreviewSpreadsFailed", null);
    __decorate([
        Action(GetPreviewSpreadsLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPreviewSpreadsLoading", null);
    __decorate([
        Action(GetPreviewSpreadsLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getPreviewSpreadsLoaded", null);
    __decorate([
        Action(GetSpreadFilters),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetSpreadFilters]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSreadFilters", null);
    __decorate([
        Action(GetSpreadFiltersFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSpreadFiltersFailed", null);
    __decorate([
        Action(GetSpreadFiltersLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSpreadFiltersLoading", null);
    __decorate([
        Action(GetSpreadFiltersLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSpreadFiltersLoaded", null);
    __decorate([
        Action(GetSpreadMarketAreas),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetSpreadMarketAreas]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSpreadMarketAreas", null);
    __decorate([
        Action(GetSpreadFiltersFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSpreadMarketAreasFailed", null);
    __decorate([
        Action(GetSpreadFiltersLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSpreadMarketAreasLoading", null);
    __decorate([
        Action(GetSpreadFiltersLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getSpreadMarketAreasLoaded", null);
    __decorate([
        Action(GetFullSpreadFilter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetFullSpreadFilter]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getFullSpreadFilter", null);
    __decorate([
        Action(GetFullSpreadFilterFailed),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getFullSpreadFilterFailed", null);
    __decorate([
        Action(GetFullSpreadFilterLoading),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getFullSpreadFilterLoading", null);
    __decorate([
        Action(GetFullSpreadFilterLoaded),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getFullSpreadFilterLoaded", null);
    __decorate([
        Action(DeleteFullSpreadFilter),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "deleteFullSpreadFilter", null);
    __decorate([
        Action(GetBlockPositions),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, GetBlockPositions]),
        __metadata("design:returntype", void 0)
    ], DashboardState.prototype, "getBlockPositions", null);
    DashboardState = __decorate([
        State({
            name: 'dashboard',
            defaults: {
                hubPrices: {
                    data: ɵ0,
                    loading: false,
                    loaded: false
                },
                lastAuctionStats: {
                    data: ɵ1,
                    loading: false,
                    loaded: false
                },
                lngCountries: {
                    data: ɵ2,
                    loading: false,
                    loaded: false
                },
                maintenancePlan: {
                    data: ɵ3,
                    loading: false,
                    loaded: false
                },
                maintenanceUpdate: {
                    data: ɵ4,
                    loading: false,
                    loaded: false
                },
                pipelineCountries: {
                    data: ɵ5,
                    loading: false,
                    loaded: false
                },
                pipelineCountriesVsLng: {
                    data: ɵ6,
                    loading: false,
                    loaded: false
                },
                spreads: {
                    data: ɵ7,
                    loading: false,
                    loaded: false,
                },
                allFilterSpreads: {
                    data: ɵ8,
                    loading: false,
                    loaded: false,
                },
                previewSpreads: {
                    data: ɵ9,
                    loading: false,
                    loaded: false,
                },
                spreadFilters: {
                    data: ɵ10,
                    loading: false,
                    loaded: false,
                },
                spreadMarketAreas: {
                    data: ɵ11,
                    loading: false,
                    loaded: false,
                },
                spreadFilterForEdit: {
                    data: ɵ12,
                    loading: false,
                    loaded: false,
                },
                blockPositions: undefined
            }
        })
    ], DashboardState);
    return DashboardState;
}());
export { DashboardState };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
