import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import privacyPolicyIcon from '../../../../assets/images/privacy-policy.svg';
import { LocalStorageKeysEnum } from '../../enums/local-storage-keys.enum';
var PrivacyPolicyPopUpComponent = /** @class */ /*@__PURE__*/ (function () {
    function PrivacyPolicyPopUpComponent(formBuilder) {
        this.formBuilder = formBuilder;
        this.gotItEmitter = new EventEmitter();
        this.componentDestroyed$ = new Subject();
        this.privacyPolicyForm = this.buildForm();
        this.privacyPolicyIcon = privacyPolicyIcon;
    }
    PrivacyPolicyPopUpComponent.prototype.ngOnInit = function () {
        this.initFormChangeListener();
    };
    PrivacyPolicyPopUpComponent.prototype.ngOnDestroy = function () {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    };
    PrivacyPolicyPopUpComponent.prototype.buildForm = function () {
        return this.formBuilder.group({
            doNotShowAgain: false
        });
    };
    PrivacyPolicyPopUpComponent.prototype.initFormChangeListener = function () {
        var _this = this;
        this.privacyPolicyForm
            .get('doNotShowAgain')
            .valueChanges.pipe(takeUntil(this.componentDestroyed$))
            .subscribe(function (value) {
            window.localStorage.setItem(_this.localStorageKey, value);
        });
    };
    return PrivacyPolicyPopUpComponent;
}());
export { PrivacyPolicyPopUpComponent };
