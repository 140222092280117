import { FormBuilder } from '@angular/forms';
import { ModalService } from 'appy-gas-core/dist';
import { ExclusiveDaysPeriodEnum } from '../../../enums/exclusive-days-period.enum';
var SwitchToExclusiveComponent = /** @class */ /*@__PURE__*/ (function () {
    function SwitchToExclusiveComponent(modalService, formBuilder) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.daysRadioInputs = [
            { label: '7 days (10 appies)', value: ExclusiveDaysPeriodEnum.SEVEN, disabled: false },
            { label: '30 days (30 appies)', value: ExclusiveDaysPeriodEnum.THIRTY, disabled: false }
        ];
        this.switchToExclusiveForm = this.createSwitchToExclusiveForm();
    }
    Object.defineProperty(SwitchToExclusiveComponent.prototype, "selectedPeriod", {
        get: function () {
            return this.switchToExclusiveForm.get('days').value;
        },
        enumerable: false,
        configurable: true
    });
    SwitchToExclusiveComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    SwitchToExclusiveComponent.prototype.switchToExclusive = function () {
        this.proceedToNextStep(this.selectedPeriod);
        this.modalService.hide();
    };
    SwitchToExclusiveComponent.prototype.createSwitchToExclusiveForm = function () {
        return this.formBuilder.group({
            days: [ExclusiveDaysPeriodEnum.SEVEN]
        });
    };
    return SwitchToExclusiveComponent;
}());
export { SwitchToExclusiveComponent };
