import { __assign } from "tslib";
import { format as formatZoneTime, utcToZonedTime } from 'date-fns-tz';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import format from 'date-fns/format';
import enGB from 'date-fns/locale/en-GB';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import mapValues from 'lodash/mapValues';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { createUtcDate, differenceInDays, MaintenanceConfig, MarketAreaNameConfig, notAvailableCapacityUnitOperators, POINTS_TOOLTIP_INFO_CONFIG } from 'appy-gas-core';
import { norwayDataSourceId, superPointIdsGRTTeregaOperator, virtualStorageIds } from '..';
import { EventClassNamesEnum } from '../../availability-pro/availability-pro-general-info/availability-pro-events-calendar/enums';
import { ImpactRadioTypes } from '../../availability-pro/availability-pro-map/availability-pro-map-filter/interfaces';
import { superPointWithTwoOperators } from '../constants/app-exceptions';
import { units } from '../constants/units.stub';
import { AvailabilityImpactEnum, AvailabilityTabEventsDirection, CapacityTypeEnum, ImpactCapacityFillColor, OperatorSourcesEnum, OperatorTypesEnum, VariableIdsEnum } from '../enums';
import * as i0 from "@angular/core";
var AvailabilityHelperService = /** @class */ /*@__PURE__*/ (function () {
    function AvailabilityHelperService() {
    }
    AvailabilityHelperService.getImpactFillColor = function (capacityType, impactPercentages) {
        if (impactPercentages === 0) {
            return ImpactCapacityFillColor.EMPTY;
        }
        switch (capacityType) {
            case CapacityTypeEnum.INTERRUPTIBLE:
                return ImpactCapacityFillColor.INTERRUPTIBLE;
            case CapacityTypeEnum.FIRM:
                return ImpactCapacityFillColor.FIRM;
            default:
                return ImpactCapacityFillColor.FIRM;
        }
    };
    AvailabilityHelperService.getMappedAllPointEvents = function (events, operators, eePoints) {
        var uniqPointEvents = uniqBy(flatten(events.map(function (item) {
            return item.events.map(function (event) { return (__assign(__assign({}, event), { virtualPointId: item.virtualPointId })); });
        })), 'eePointId');
        return uniqPointEvents.map(function (event) {
            var _a;
            var operator = operators.find(function (value) { return value.id === event.operatorId; });
            var point = eePoints.find(function (value) { return value.eePointId === event.eePointId; });
            var marketAreasIds = ((_a = MaintenanceConfig[event.virtualPointId]) === null || _a === void 0 ? void 0 : _a.fullMarketAreasIds) || [
                event.marketAreaId,
                event.marketAreaId
            ];
            return __assign(__assign({}, event), { operatorName: operator.name, pointName: point.name, marketAreasIds: marketAreasIds });
        });
    };
    AvailabilityHelperService.getMaintenanceUIEvents = function (operators, selectedEvents, eePoints, maintenanceFiltersForm, isNorwayPointSelected) {
        var events = maintenanceFiltersForm
            ? AvailabilityHelperService.filterMaintenanceEvents(selectedEvents, maintenanceFiltersForm, isNorwayPointSelected)
            : selectedEvents;
        var sortedByRange = sortBy(events, ['maintenanceStart', 'maintenanceEnd']);
        return sortedByRange.map(function (eventDetails) {
            var impactOnTac;
            var impactOnFirm;
            var operator = operators.find(function (value) { return value.id === eventDetails.operatorId; });
            var point = eePoints.find(function (value) { return value.eePointId === eventDetails.eePointId; });
            impactOnTac = round(eventDetails.impactCapacityInPercents * 100, 0);
            if (notAvailableCapacityUnitOperators.includes(operator.name) ||
                (virtualStorageIds.includes(eventDetails.virtualPointId) && eventDetails.capacityType === OperatorTypesEnum.SSO)) {
                impactOnFirm = AvailabilityImpactEnum.NOT_AVAILABLE;
            }
            else {
                impactOnFirm = round(eventDetails.impactBookedPercentage * 100, 0);
            }
            return {
                marketAreaName: MarketAreaNameConfig[eventDetails.marketAreaId],
                // TODO uncomment this when super points with two operators removed
                // operatorName: operator.name,
                operatorName: superPointIdsGRTTeregaOperator.includes(eventDetails.eePointId)
                    ? superPointWithTwoOperators[eventDetails.eePointId]
                    : operator.name,
                operatorTypeId: eventDetails.operatorTypeId,
                dataSourceId: eventDetails.dataSourceId,
                operatorId: eventDetails.operatorId,
                pointName: point.name,
                pointId: eventDetails.eePointId,
                duration: !!eventDetails.maintenanceStart && !!eventDetails.maintenanceEnd
                    ? format(eventDetails.maintenanceStart, 'dd.MM') + " - " + format(eventDetails.maintenanceEnd, 'dd.MM')
                    : '-',
                expectedInterruption: eventDetails.expectedInterruption,
                expectedInterruptionUnit: eventDetails.expectedInterruptionUnit,
                impactOnTac: impactOnTac,
                impactOnFirm: impactOnFirm,
                impactOnTacFillColor: AvailabilityHelperService.getImpactFillColor(eventDetails.capacityType, impactOnTac),
                impactOnFirmFillColor: AvailabilityHelperService.getImpactFillColor(eventDetails.capacityType, impactOnFirm),
                isOneMADirection: !eventDetails.marketAreasIds || eventDetails.marketAreasIds.length === 0,
                flowDirection: AvailabilityHelperService.getFlowDirection(eventDetails.marketAreasIds, eventDetails.marketAreaId, eventDetails.directionId),
                maintenanceEnd: eventDetails.maintenanceEnd,
                maintenanceStart: eventDetails.maintenanceStart,
                virtualPointId: eventDetails.virtualPointId,
                capacityType: eventDetails.capacityType,
                directionId: eventDetails.directionId,
                tooltipData: AvailabilityHelperService.getEventTooltip(eventDetails.eePointId),
                hasMaintenance: !!eventDetails.maintenanceStart && !!eventDetails.maintenanceEnd,
                isRemainingPercentsSetByOperator: eventDetails.isRemainingPercentsSetByOperator,
                isRemainingCapacitySetByOperator: eventDetails.isRemainingCapacitySetByOperator
            };
        });
    };
    AvailabilityHelperService.getFlowDirection = function (relatedMarketAreaIds, eventMarketAreaId, direction) {
        if (relatedMarketAreaIds === void 0) {
            relatedMarketAreaIds = [];
        }
        var isOneMADirection = relatedMarketAreaIds.length === 0;
        if (isOneMADirection) {
            return AvailabilityTabEventsDirection.ENTRY;
        }
        if (head(relatedMarketAreaIds) === eventMarketAreaId) {
            return direction > 0 ? AvailabilityTabEventsDirection.EXIT : AvailabilityTabEventsDirection.ENTRY;
        }
        else {
            return direction < 0 ? AvailabilityTabEventsDirection.EXIT : AvailabilityTabEventsDirection.ENTRY;
        }
    };
    AvailabilityHelperService.filterMaintenancePoints = function (maintenancePoint, maintenanceFiltersForm, isPointsGasscoEventsIncluded) {
        var tso = maintenanceFiltersForm.tso, sso = maintenanceFiltersForm.sso, firm = maintenanceFiltersForm.firm, interruptible = maintenanceFiltersForm.interruptible, impact = maintenanceFiltersForm.impact;
        var filteredMaintenances = maintenancePoint.maintenanceGeneral;
        filteredMaintenances = filteredMaintenances.filter(function (item) {
            if (!tso) {
                return item.operatorType !== OperatorTypesEnum.TSO;
            }
            return true;
        });
        filteredMaintenances = filteredMaintenances.filter(function (item) {
            if (!sso) {
                return item.operatorType !== OperatorTypesEnum.SSO;
            }
            return true;
        });
        filteredMaintenances = filteredMaintenances.filter(function (item) {
            if (!firm) {
                return item.maintenanceType < 0;
            }
            return true;
        });
        filteredMaintenances = filteredMaintenances.filter(function (item) {
            if (!interruptible) {
                return item.maintenanceType > 0;
            }
            return true;
        });
        filteredMaintenances = filteredMaintenances.filter(function (item) {
            switch (impact) {
                case ImpactRadioTypes.LESS:
                    return item.impactInPercents < 0.8;
                case ImpactRadioTypes.EQUALS:
                    return item.impactInPercents === 1;
                default:
                    return ImpactRadioTypes.ALL;
            }
        });
        if (isPointsGasscoEventsIncluded) {
            maintenancePoint.maintenanceFilteredByGassco = filteredMaintenances;
        }
        else {
            maintenancePoint.maintenanceGeneralFiltered = filteredMaintenances.filter(function (maintenance) {
                return maintenance.dataSourceId !== norwayDataSourceId;
            });
        }
        return maintenancePoint;
    };
    AvailabilityHelperService.filterMaintenanceEvents = function (maintenanceEvents, maintenanceFiltersForm, isNorwayPointSelected) {
        var tso = maintenanceFiltersForm.tso, sso = maintenanceFiltersForm.sso, firm = maintenanceFiltersForm.firm, interruptible = maintenanceFiltersForm.interruptible, impact = maintenanceFiltersForm.impact;
        maintenanceEvents = maintenanceEvents.filter(function (item) {
            if (!tso) {
                return item.operatorTypeId !== OperatorTypesEnum.TSO;
            }
            return true;
        });
        maintenanceEvents = maintenanceEvents.filter(function (item) {
            if (!sso) {
                return item.operatorTypeId !== OperatorTypesEnum.SSO;
            }
            return true;
        });
        maintenanceEvents = maintenanceEvents.filter(function (item) {
            if (!firm) {
                return item.capacityType !== CapacityTypeEnum.FIRM;
            }
            return true;
        });
        maintenanceEvents = maintenanceEvents.filter(function (item) {
            if (!interruptible) {
                return item.capacityType !== CapacityTypeEnum.INTERRUPTIBLE;
            }
            return true;
        });
        maintenanceEvents = maintenanceEvents.filter(function (item) {
            switch (impact) {
                case ImpactRadioTypes.LESS:
                    return item.impactCapacityInPercents < 0.8;
                case ImpactRadioTypes.EQUALS:
                    return item.impactCapacityInPercents === 1;
                default:
                    return ImpactRadioTypes.ALL;
            }
        });
        if (!isNorwayPointSelected) {
            return maintenanceEvents.filter(function (event) { return event.dataSourceId !== norwayDataSourceId; });
        }
        else {
            return maintenanceEvents;
        }
    };
    AvailabilityHelperService.transformRelatedPoints = function (relatedPoints, eePoints, operators) {
        return relatedPoints.map(function (point) {
            var _a;
            var events = point.maintenances.map(function (maintenance) {
                return ({
                    maintenanceStart: maintenance.maintenanceStart,
                    maintenanceEnd: maintenance.maintenanceEnd,
                    directionId: maintenance.directionId,
                    capacityType: maintenance.capacityType,
                    dataSourceId: maintenance.dataSourceId
                });
            });
            var eePoint = eePoints.find(function (_a) {
                var eePointId = _a.eePointId;
                return eePointId === point.id;
            });
            return {
                id: point.id,
                name: eePoint === null || eePoint === void 0 ? void 0 : eePoint.name,
                operatorName: (_a = operators.find(function (_a) {
                    var id = _a.id;
                    return id === (eePoint === null || eePoint === void 0 ? void 0 : eePoint.operatorId);
                })) === null || _a === void 0 ? void 0 : _a.name,
                events: events
            };
        });
    };
    AvailabilityHelperService.transformEventDetails = function (eventDetails) {
        var _a, _b, _c;
        var diffInDays = differenceInDays(eventDetails.maintenanceStart, eventDetails.maintenanceEnd);
        var expectedInterruptionUnitName = (_a = units.find(function (unit) { return unit.id === (eventDetails === null || eventDetails === void 0 ? void 0 : eventDetails.expectedInterruptionUnit); })) === null || _a === void 0 ? void 0 : _a.abbreviaton;
        var remainingCapacityUnitName = (_b = units.find(function (unit) { return unit.id === (eventDetails === null || eventDetails === void 0 ? void 0 : eventDetails.remainingCapacityUnit); })) === null || _b === void 0 ? void 0 : _b.abbreviaton;
        var bookedCapacityUnitName = (_c = units.find(function (unit) { return unit.id === (eventDetails === null || eventDetails === void 0 ? void 0 : eventDetails.bookedCapacityUnit); })) === null || _c === void 0 ? void 0 : _c.abbreviaton;
        return __assign(__assign({}, eventDetails), { maintenanceEndFormatted: AvailabilityHelperService.getZonedTime(eventDetails.maintenanceEnd), maintenanceStartFormatted: AvailabilityHelperService.getZonedTime(eventDetails.maintenanceStart), expectedInterruptionUnitName: expectedInterruptionUnitName,
            remainingCapacityUnitName: remainingCapacityUnitName,
            bookedCapacityUnitName: bookedCapacityUnitName,
            diffInDays: diffInDays });
    };
    AvailabilityHelperService.getCalendarEventWithAppropriateClasses = function (events, points) {
        var sortedByDate = sortBy(events, ['maintenanceStart']);
        var clonedEvents = cloneDeep(sortedByDate);
        clonedEvents.forEach(function (event, index) {
            event.rowClass = index === 0 ? EventClassNamesEnum.FIRST_ROW : '';
            var eventsToCompare = clonedEvents.slice(0, index);
            var overlappedEventsClasses = eventsToCompare
                .filter(function (ev) {
                return areIntervalsOverlapping({ start: event.maintenanceStart, end: event.maintenanceEnd }, { start: ev.maintenanceStart, end: ev.maintenanceEnd }, { inclusive: true });
            })
                .map(function (overlappedEvent) { return overlappedEvent.rowClass; });
            if (overlappedEventsClasses.length) {
                if (!overlappedEventsClasses.includes(EventClassNamesEnum.FIRST_ROW)) {
                    event.rowClass = EventClassNamesEnum.FIRST_ROW;
                }
                else if (!overlappedEventsClasses.includes(EventClassNamesEnum.SECOND_ROW)) {
                    event.rowClass = EventClassNamesEnum.SECOND_ROW;
                }
                else if (!overlappedEventsClasses.includes(EventClassNamesEnum.THIRD_ROW)) {
                    event.rowClass = EventClassNamesEnum.THIRD_ROW;
                }
                else if (!overlappedEventsClasses.includes(EventClassNamesEnum.FOURTH_ROW)) {
                    event.rowClass = EventClassNamesEnum.FOURTH_ROW;
                }
                else {
                    event.rowClass = EventClassNamesEnum.HIDDEN_ROW;
                }
            }
            else {
                event.rowClass = EventClassNamesEnum.FIRST_ROW;
            }
            return event;
        });
        return clonedEvents.map(function (event) {
            return {
                start: new Date(event.maintenanceStart),
                end: new Date(event.maintenanceEnd),
                title: '',
                cssClass: event.isActive
                    ? (event.rowClass += " " + EventClassNamesEnum.CURRENT_EVENT)
                    : (event.rowClass += " " + EventClassNamesEnum.OTHER_EVENT),
                meta: {
                    pointName: points.find(function (point) { return event.eePointId === point.eePointId; }).name
                }
            };
        });
    };
    AvailabilityHelperService.transformImpactChartData = function (pointData, eventDetails) {
        var pointDataTimeReduction = pointData.map(function (point) {
            return __assign(__assign({}, point), { validityStart: new Date(point.validityStart).setHours(7, 0, 0, 0) });
        });
        var groupedByVariableId = groupBy(pointDataTimeReduction, 'eePointVariableId');
        var groupedByVariableIdAndDate = mapValues(groupedByVariableId, function (dataItems) {
            return dataItems.reduce(function (acc, currentItem) {
                var _a;
                return __assign(__assign({}, acc), (_a = {}, _a[currentItem.validityStart] = { value: currentItem.value, unit: currentItem.unitId }, _a));
            }, {});
        });
        return {
            newTac: groupedByVariableIdAndDate[VariableIdsEnum.REMAINING_CAPACITY],
            originalTac: groupedByVariableIdAndDate[VariableIdsEnum.IP_TECHNICAL_ENTRY] ||
                groupedByVariableIdAndDate[VariableIdsEnum.IP_TECHNICAL_EXIT] ||
                groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_TECHNICAL_ENTRY] ||
                groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_TECHNICAL_EXIT],
            bookedFirm: groupedByVariableIdAndDate[VariableIdsEnum.IP_BOOKED_FIRM_ENTRY] ||
                groupedByVariableIdAndDate[VariableIdsEnum.IP_BOOKED_FIRM_EXIT] ||
                groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_BOOKED_FIRM_ENTRY] ||
                groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_BOOKED_FIRM_EXIT],
            bookedInterruptible: groupedByVariableIdAndDate[VariableIdsEnum.IP_BOOKED_INTERRUPTIBLE_ENTRY] ||
                groupedByVariableIdAndDate[VariableIdsEnum.IP_BOOKED_INTERRUPTIBLE_EXIT] ||
                groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_BOOKED_INTERRUPTIBLE_ENTRY] ||
                groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_BOOKED_INTERRUPTIBLE_EXIT],
            flow: groupedByVariableIdAndDate[VariableIdsEnum.IP_CLEAN_NOM_ENTRY] ||
                groupedByVariableIdAndDate[VariableIdsEnum.IP_CLEAN_NOM_EXIT] ||
                groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_CLEAN_NOM_ENTRY] ||
                groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_CLEAN_NOM_EXIT],
            eventDetails: eventDetails
        };
    };
    AvailabilityHelperService.filterRemainingTacByAvailableOperators = function (pointItem, expectedInterruption) {
        return (expectedInterruption === 0 &&
            pointItem.eePointVariableId === VariableIdsEnum.REMAINING_CAPACITY &&
            pointItem.dataSourceId === OperatorSourcesEnum.GUD);
    };
    AvailabilityHelperService.getEventTooltip = function (pointId) {
        return POINTS_TOOLTIP_INFO_CONFIG[pointId] || '';
    };
    AvailabilityHelperService.getZonedUTCTime = function (timestamp) {
        var date = createUtcDate(timestamp);
        return formatZoneTime(date, 'dd.MM.y, h:mm a (zzz)', { timeZone: 'Europe/Paris', locale: enGB });
    };
    AvailabilityHelperService.getZonedTime = function (date) {
        var utcZonedDate = utcToZonedTime(date, 'Europe/Paris');
        return formatZoneTime(utcZonedDate, 'dd.MM.y, h:mm a (zzz)', { timeZone: 'Europe/Paris', locale: enGB });
    };
    AvailabilityHelperService.ɵprov = i0.ɵɵdefineInjectable({ factory: function AvailabilityHelperService_Factory() { return new AvailabilityHelperService(); }, token: AvailabilityHelperService, providedIn: "root" });
    return AvailabilityHelperService;
}());
export { AvailabilityHelperService };
