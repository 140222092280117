import { __assign } from "tslib";
import { Store } from '@ngxs/store';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { GrantType, ModalService, Role, TokenType } from 'appy-gas-core';
import { AuthenticationService } from '../../core/http-auth';
import { getMetadataHeaders } from '../../core/http-auth/headers.helper';
import { AuthInterceptor } from '../../core/http-auth/http-interceptors/auth-interceptor';
import { ProfileSelectors, UserSelectors } from '../../store';
import { infoModalConfig, restrictedMarketAreaClassicConfig, restrictedMarketAreaPremiumConfig, switchDeclinedModalConfig, switchToExclusiveDaysConfig, switchToExclusiveInfoModalConfig, switchToExclusiveModalConfig, youAreExclusiveModalConfig } from '../components/user-transition-modals';
import { restrictedFeatureMAs } from '../constants/app-exceptions';
import { LocalStorageKeysEnum } from '../enums/local-storage-keys.enum';
import { UpdateUserRoleResponseTypes } from '../enums/update-user-role-response-types.enum';
import * as i0 from "@angular/core";
import * as i1 from "../../core/http-auth/authentication.service";
import * as i2 from "@angular/common/http";
import * as i3 from "@ngxs/store";
import * as i4 from "appy-gas-core/dist/components/modal/modal.service";
var UserRoleTransitionService = /** @class */ /*@__PURE__*/ (function () {
    function UserRoleTransitionService(authService, http, store, modalService, injector) {
        this.authService = authService;
        this.http = http;
        this.store = store;
        this.modalService = modalService;
        this.injector = injector;
        this.handleDynamicTransitionModals();
    }
    Object.defineProperty(UserRoleTransitionService.prototype, "userRole", {
        get: function () {
            return this.store.selectSnapshot(UserSelectors.getUserRole);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserRoleTransitionService.prototype, "isTrialExclusiveUsed", {
        get: function () {
            return this.store.selectSnapshot(UserSelectors.isTrialExclusiveUsed);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserRoleTransitionService.prototype, "appiesCount", {
        get: function () {
            return this.store.selectSnapshot(ProfileSelectors.appiesCount);
        },
        enumerable: false,
        configurable: true
    });
    UserRoleTransitionService.prototype.switchToExclusive = function () {
        if (this.userRole === Role.Classic) {
            this.switchForClassicUser();
            return;
        }
        else if (this.userRole === Role.PremiumPlus ||
            this.userRole === Role.Premium ||
            this.userRole === Role.Expired ||
            this.userRole === Role.Free ||
            this.userRole === Role.PreClassic ||
            this.userRole === Role.PromoClassic ||
            (this.userRole === Role.TrialClassic && this.isTrialExclusiveUsed)) {
            this.showRestrictedFeatureModal();
            return;
        }
        else if (this.userRole === Role.TrialClassic && !this.isTrialExclusiveUsed) {
            this.switchForTrialClassicUser();
            return;
        }
        console.log('Unknown role', this.userRole);
    };
    UserRoleTransitionService.prototype.getRestrictedMAFeatureByAppyGasId = function (appyGasId) {
        var restrictedMarketArea = restrictedFeatureMAs.find(function (restrictedFeatureMA) {
            return restrictedFeatureMA.VIPIds.includes(appyGasId);
        });
        if (this.isRestrictedMAIsValid(restrictedMarketArea)) {
            return restrictedMarketArea;
        }
    };
    UserRoleTransitionService.prototype.getRestrictedMAFeatureByMAId = function (marketAreaId) {
        var restrictedMarketArea = restrictedFeatureMAs.find(function (restrictedMAData) { return restrictedMAData.marketAreaId === marketAreaId; });
        if (this.isRestrictedMAIsValid(restrictedMarketArea)) {
            return restrictedMarketArea;
        }
    };
    UserRoleTransitionService.prototype.getRestrictedMAFeatureByPointId = function (pointId) {
        var restrictedMarketArea = restrictedFeatureMAs.find(function (restrictedFeatureMA) {
            return restrictedFeatureMA.pointIds.includes(pointId);
        });
        if (this.isRestrictedMAIsValid(restrictedMarketArea)) {
            return restrictedMarketArea;
        }
    };
    UserRoleTransitionService.prototype.showRestrictedMAsFeatureModal = function (restrictedMA) {
        var _this = this;
        if ([Role.PremiumPlus, Role.Premium].includes(this.userRole)) {
            this.modalService.show(this.injector, restrictedMarketAreaPremiumConfig);
        }
        else {
            var config = __assign(__assign({}, restrictedMarketAreaClassicConfig), { component: __assign(__assign({}, restrictedMarketAreaClassicConfig.component), { options: __assign(__assign({}, restrictedMarketAreaClassicConfig.component.options), { activatedDate: restrictedMA.activatedDate, proceedToNextStep: function () { return _this.switchToExclusive(); } }) }) });
            this.modalService.show(this.injector, config);
        }
    };
    UserRoleTransitionService.prototype.showRestrictedFeatureModal = function () {
        this.modalService.show(this.injector, switchToExclusiveInfoModalConfig);
    };
    UserRoleTransitionService.prototype.switchForClassicUser = function () {
        var _this = this;
        var config = __assign(__assign({}, switchToExclusiveModalConfig), { component: __assign(__assign({}, switchToExclusiveModalConfig.component), { options: __assign(__assign({}, switchToExclusiveModalConfig.component.options), { appiesCount: this.appiesCount, proceedToNextStep: function (period) { return _this.switchToExclusiveByPeriod(period); } }) }) });
        this.modalService.show(this.injector, config);
    };
    UserRoleTransitionService.prototype.switchForTrialClassicUser = function () {
        var _this = this;
        var config = __assign(__assign({}, switchToExclusiveDaysConfig), { component: __assign(__assign({}, switchToExclusiveDaysConfig.component), { options: __assign(__assign({}, switchToExclusiveDaysConfig.component.options), { proceedToNextStep: function () { return _this.switchToExclusiveForTrialUser(); } }) }) });
        this.modalService.show(this.injector, config);
    };
    UserRoleTransitionService.prototype.showNotEnoughMoneyModal = function () {
        this.modalService.show(this.injector, switchDeclinedModalConfig);
    };
    UserRoleTransitionService.prototype.switchToExclusiveByPeriod = function (period) {
        var _this = this;
        this.http
            .post("/accounts/upgrade/exclusive/" + period, {}, { headers: getMetadataHeaders({ auth: true }) })
            .subscribe(function () { return _this.upgradeUser(period); }, function (_a) {
            var error = _a.error;
            if (error.Key === UpdateUserRoleResponseTypes.NOT_ENOUGH_MONEY) {
                _this.showNotEnoughMoneyModal();
            }
        });
    };
    UserRoleTransitionService.prototype.switchToExclusiveForTrialUser = function () {
        var _this = this;
        this.http
            .post("/accounts/upgrade/TrialExclusive", {}, { headers: getMetadataHeaders({ auth: true }) })
            .subscribe(function () { return _this.upgradeTrialUser(); });
    };
    UserRoleTransitionService.prototype.upgradeUser = function (period) {
        var refreshToken = AuthInterceptor.getToken(TokenType.REFRESH);
        this.authService
            .refreshToken({
            refresh_token: refreshToken,
            grant_type: GrantType.REFRESH_TOKEN
        })
            .subscribe(function () {
            localStorage.setItem(LocalStorageKeysEnum.USER_ROLE_INFO_MODAL, JSON.stringify(period));
        });
    };
    UserRoleTransitionService.prototype.upgradeTrialUser = function () {
        var refreshToken = AuthInterceptor.getToken(TokenType.REFRESH);
        this.authService
            .refreshToken({
            refresh_token: refreshToken,
            grant_type: GrantType.REFRESH_TOKEN
        })
            .subscribe(function () {
            localStorage.setItem(LocalStorageKeysEnum.USER_ROLE_EXCLUSIVE_MODAL, JSON.stringify(true));
        });
    };
    UserRoleTransitionService.prototype.showSuccessfulUpdateModal = function (accessDays) {
        var dateAccessEnd = addDays(new Date(), accessDays);
        var config = __assign(__assign({}, infoModalConfig), { component: __assign(__assign({}, infoModalConfig.component), { options: __assign(__assign({}, infoModalConfig.component.options), { accessDays: accessDays,
                    dateAccessEnd: dateAccessEnd }) }) });
        this.modalService.show(this.injector, config);
    };
    UserRoleTransitionService.prototype.showSuccessfulTrialUpdateModal = function () {
        this.modalService.show(this.injector, youAreExclusiveModalConfig);
    };
    UserRoleTransitionService.prototype.handleDynamicTransitionModals = function () {
        var showUserRoleInfoModal = localStorage.getItem(LocalStorageKeysEnum.USER_ROLE_INFO_MODAL);
        var showUserRoleExclusiveModal = localStorage.getItem(LocalStorageKeysEnum.USER_ROLE_EXCLUSIVE_MODAL);
        if (showUserRoleInfoModal) {
            this.showSuccessfulUpdateModal(JSON.parse(showUserRoleInfoModal));
        }
        if (showUserRoleExclusiveModal) {
            this.showSuccessfulTrialUpdateModal();
        }
    };
    UserRoleTransitionService.prototype.isRestrictedMAIsValid = function (restrictedMA) {
        return isAfter(restrictedMA === null || restrictedMA === void 0 ? void 0 : restrictedMA.activatedDate, new Date()) && (restrictedMA === null || restrictedMA === void 0 ? void 0 : restrictedMA.roles.includes(this.userRole));
    };
    UserRoleTransitionService.ɵprov = i0.ɵɵdefineInjectable({ factory: function UserRoleTransitionService_Factory() { return new UserRoleTransitionService(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Store), i0.ɵɵinject(i4.ModalService), i0.ɵɵinject(i0.INJECTOR)); }, token: UserRoleTransitionService, providedIn: "root" });
    return UserRoleTransitionService;
}());
export { UserRoleTransitionService };
