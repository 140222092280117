import { __extends } from "tslib";
import { Store } from '@ngxs/store';
import * as decode from 'jwt-decode';
import { StateResetAll } from 'ngxs-reset-plugin';
import { BehaviorSubject, of as ObservableOf } from 'rxjs';
import * as spacetime from 'spacetime';
import { HttpClient } from '@angular/common/http';
import { BaseAuthService, Role, TokenType } from 'appy-gas-core';
import { APPYGAS_TOKENS } from './auth-config';
var AuthenticationService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(AuthenticationService, _super);
    function AuthenticationService(httpClient, store) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.store = store;
        _this.roleSource = new BehaviorSubject(Role.Guest);
        _this.roleExpSource = new BehaviorSubject(null);
        _this.roleExpDays = _this.roleExpSource.asObservable();
        _this.role = _this.roleSource.asObservable();
        var token = _this.getToken(TokenType.ACCESS);
        _this.propagateToken(TokenType.ACCESS, token);
        _this.saveRole(token);
        return _this;
    }
    AuthenticationService.prototype.getToken = function (type) {
        return localStorage.getItem(APPYGAS_TOKENS[type]); // TODO: create a localStorage service;
    };
    AuthenticationService.prototype.getUser = function () {
        if (!this._token) {
            return ObservableOf(null);
        }
        return this.userinfo();
    };
    AuthenticationService.prototype.logout = function () {
        this.store.dispatch(new StateResetAll());
        return _super.prototype.logout.call(this);
    };
    AuthenticationService.prototype.saveToken = function (type, token) {
        if (type === TokenType.ACCESS) {
            this.saveRole(token);
        }
        if (token) {
            localStorage.setItem(APPYGAS_TOKENS[type], token);
        }
        else {
            localStorage.removeItem(APPYGAS_TOKENS[type]);
        }
        return this.propagateToken(type, token);
    };
    AuthenticationService.prototype.flushRole = function () {
        this.roleSource.next(Role.Guest);
    };
    AuthenticationService.prototype.allowRoles = function (names) {
        if (!names || names === Role.All || (Array.isArray(names) && (names.length === 0 || names.includes(Role.All)))) {
            return Promise.resolve(true);
        }
        return this.hasRoleKey(names);
    };
    AuthenticationService.prototype.saveRole = function (token) {
        if (token) {
            var tokenPayload = decode(token);
            this.roleSource.next(parseInt(tokenPayload.roleId, 10));
            this.setRoleExpiration(tokenPayload.roleExpDay);
        }
    };
    AuthenticationService.prototype.setRoleExpiration = function (roleExpDay) {
        var expirationDate = spacetime(parseInt(roleExpDay, 10) * 1000);
        if (expirationDate.year() >= 5000) {
            this.roleExpSource.next(null);
            return;
        }
        var roleLeftDays = spacetime.now().diff(expirationDate, 'day');
        this.roleExpSource.next(roleLeftDays);
    };
    AuthenticationService.prototype.hasRoleKey = function (roleName) {
        if (Array.isArray(roleName)) {
            return Promise.resolve(roleName.includes(this.roleSource.value));
        }
        else {
            return Promise.resolve(!!this.roleSource.value[roleName]);
        }
    };
    return AuthenticationService;
}(BaseAuthService));
export { AuthenticationService };
