import { ModalConfig } from 'appy-gas-core';

import { ForgotPasswordComponent } from './forgot-password.component';

const forgotPasswordModalConfig: ModalConfig = {
  title: 'Forgot Password',
  component: {
    name: ForgotPasswordComponent,
    options: {
      isPage: false
    }
  },
  data: {
    width: 670
  }
};

export default forgotPasswordModalConfig;
