import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { EnvVariables } from 'appy-gas-core';

@Injectable()
export class TechnicalMaintenanceGuard implements CanActivate {
  // tslint:disable-next-line:typedef
  constructor(private router: Router, @Inject(EnvVariables) private envVariables) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.envVariables.technicalMaintenance) {
      this.router.navigate(['/technical-maintenance']);
      return false;
    }
    return true;
  }
}
