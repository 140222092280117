import isAfter from 'date-fns/isAfter';
import { mergedGermanyMADate, mergedGermanyMAs, notMergedGermanyMAs } from '../constants/app-constant';
import { ListExceptMarketAreaView, virtualPointIdsAfterGermanyMerge, virtualPointIdsBeforeGermanyMerge } from '../constants/app-exceptions';
var HeaderHelperService = /** @class */ /*@__PURE__*/ (function () {
    function HeaderHelperService() {
    }
    HeaderHelperService.filterSearchResultByGermanyMergeState = function (searchPointsResult, selectedDate) {
        var isGermanyMerged = isAfter(selectedDate, mergedGermanyMADate);
        return searchPointsResult
            .filter(function (searchItem) {
            var filterKey = searchItem.marketAreaId !== 0 ? searchItem.marketAreaId : searchItem.id;
            return isGermanyMerged ? !notMergedGermanyMAs.includes(filterKey) : !mergedGermanyMAs.includes(filterKey);
        })
            .filter(function (searchItem) {
            return isGermanyMerged
                ? !virtualPointIdsBeforeGermanyMerge.includes(searchItem.appyGasId)
                : !virtualPointIdsAfterGermanyMerge.includes(searchItem.appyGasId);
        })
            .filter(function (searchItem) { return !ListExceptMarketAreaView.includes(searchItem.marketAreaId); });
    };
    return HeaderHelperService;
}());
export { HeaderHelperService };
