import { UpgradePremiumComponent } from './upgrade-premium.component';

export const upgradePremiumModalConfig = {
  title: 'This feature is available for premium users only',
  component: {
    name: UpgradePremiumComponent,
    options: {}
  },
  data: {
    width: 670
  }
};
