export var OrderClassesEnum = /*@__PURE__*/ (function (OrderClassesEnum) {
    OrderClassesEnum["FIRST"] = "first";
    OrderClassesEnum["SECOND"] = "second";
    OrderClassesEnum["THIRD"] = "third";
    OrderClassesEnum["FOURTH"] = "fourth";
    OrderClassesEnum["FIFTH"] = "fifth";
    OrderClassesEnum["SIXTH"] = "sixth";
    OrderClassesEnum["SEVENTH"] = "seventh";
    OrderClassesEnum["EIGHTH"] = "eighth";
    OrderClassesEnum["NINTH"] = "ninth";
    return OrderClassesEnum;
})({});
