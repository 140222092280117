import each from 'lodash/each';
import { finalize } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { emailValidator } from 'appy-gas-core';
import { AuthenticationService } from '../../core/http-auth/authentication.service';
import { AuthModalHolderService } from '../auth-modal-holder.service';
import { AuthModalType } from '../auth-modal-type.enum';
var ForgotPasswordComponent = /** @class */ /*@__PURE__*/ (function () {
    function ForgotPasswordComponent(modalHolder, router, formBuilder, authenticationService) {
        this.modalHolder = modalHolder;
        this.router = router;
        this.formBuilder = formBuilder;
        this.authenticationService = authenticationService;
        this.isPage = true;
        this.error = null;
        this.isLoading = false;
        this.emailWasSent = false;
        this.title = 'Forgot your password';
        this.description = "Enter the email address associated with your account,\n  and we will send you an email with further instructions on how to change your password";
        this.createForm();
    }
    ForgotPasswordComponent.prototype.forgot = function () {
        var _this = this;
        if (!this.forgotForm.valid) {
            each(this.forgotForm.controls, function (control) { return control.markAsTouched(); });
            return;
        }
        this.isLoading = true;
        this.authenticationService
            .forgotPassword(this.forgotForm.value)
            .pipe(finalize(function () {
            _this.forgotForm.markAsPristine();
            _this.isLoading = false;
        }))
            .subscribe(function () {
            _this.title = 'Check your email!';
            _this.description = "We\u2019ve sent a message to <b>" + _this.forgotForm.controls.email.value + "</b>. Open it and follow the instructions.";
            _this.emailWasSent = true;
        }, function (error) { return (_this.error = error); });
    };
    ForgotPasswordComponent.prototype.signIn = function () {
        if (this.isPage) {
            this.router.navigate(['/auth/login']);
        }
        else {
            this.modalHolder.put(AuthModalType.LOGIN);
        }
    };
    ForgotPasswordComponent.prototype.createForm = function () {
        this.forgotForm = this.formBuilder.group({
            email: ['', [Validators.required, emailValidator]]
        });
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
