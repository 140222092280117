var GetExportData = /** @class */ /*@__PURE__*/ (function () {
    function GetExportData() {
    }
    GetExportData.type = '[Data Export Module] Get Export Data';
    return GetExportData;
}());
export { GetExportData };
var GetExportDataSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetExportDataSuccess(exportData) {
        this.exportData = exportData;
    }
    GetExportDataSuccess.type = '[Data Export Module] Get Export Data Success';
    return GetExportDataSuccess;
}());
export { GetExportDataSuccess };
var GetExportDataFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetExportDataFailed() {
    }
    GetExportDataFailed.type = '[Data Export Module] Get Export Data Failed';
    return GetExportDataFailed;
}());
export { GetExportDataFailed };
