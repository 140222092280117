import { EnvironmentVariables, HeaderMetaDataOptions, InterceptorConfig, Logger } from 'appy-gas-core';
var RequestCache = /** @class */ /*@__PURE__*/ (function () {
    function RequestCache() {
    }
    return RequestCache;
}());
export { RequestCache };
var log = new Logger('HttpCaching');
var RequestCacheWithMap = /** @class */ /*@__PURE__*/ (function () {
    function RequestCacheWithMap(envVariables) {
        this.envVariables = envVariables;
        this.cacheEntries = new Map();
        this.config = new InterceptorConfig({ serverUrl: envVariables.serverUrl });
    }
    RequestCacheWithMap.prototype.get = function (req, headerMetaData) {
        var url = req.url;
        var cached = this.cacheEntries.get(url);
        if (!cached) {
            return undefined;
        }
        var isExpired = cached.lastRead < Date.now() - headerMetaData.cacheMaxAge;
        var expired = isExpired ? 'expired ' : '';
        log.info("Found " + expired + "cached response for \"" + url + "\".");
        return isExpired ? undefined : cached.response;
    };
    RequestCacheWithMap.prototype.put = function (_a, response, headerMetaData) {
        var _this = this;
        var url = _a.url;
        var requestCacheEntry = {
            url: url,
            response: response,
            lastRead: Date.now(),
            maxCacheAge: headerMetaData.cacheMaxAge
        };
        log.info("Caching response from \"" + url + "\".");
        this.cacheEntries.set(url, requestCacheEntry);
        // remove expired cache entries
        var expired = Date.now() - headerMetaData.cacheMaxAge;
        this.cacheEntries.forEach(function (cacheEntry) {
            if (cacheEntry.lastRead < expired) {
                _this.cacheEntries.delete(cacheEntry.url);
            }
        });
        log.info("Request cache size: " + this.cacheEntries.size + ".");
    };
    return RequestCacheWithMap;
}());
export { RequestCacheWithMap };
