import each from 'lodash/each';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { emailValidator, ModalService, Role } from 'appy-gas-core';
import { AuthenticationService } from '../../core/http-auth/authentication.service';
import { AuthModalHolderService } from '../auth-modal-holder.service';
import { AuthModalType } from '../auth-modal-type.enum';
var LoginComponent = /** @class */ /*@__PURE__*/ (function () {
    function LoginComponent(router, route, formBuilder, modalService, authenticationService, modalHolder) {
        this.router = router;
        this.route = route;
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.authenticationService = authenticationService;
        this.modalHolder = modalHolder;
        this.isPage = true;
        this.redirectUrl = '/';
        this.error = null;
        this.isLoading = false;
        this.passwordSuccessfullyChanged = false;
        this.componentDestroyed$ = new Subject();
        this.createForm();
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authenticationService.logout();
        this.route.params.subscribe(function (params) {
            _this.passwordSuccessfullyChanged = params.passwordSuccessfullyChanged;
        });
        this.authenticationService.role.pipe(takeUntil(this.componentDestroyed$)).subscribe(function (role) {
            var redirectedUrl = role === Role.Expired ? '/profile' : '/';
            _this.router.navigateByUrl(redirectedUrl);
        });
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    };
    LoginComponent.prototype.login = function () {
        var _this = this;
        if (!this.loginForm.valid) {
            each(this.loginForm.controls, function (control) { return control.markAsTouched(); });
            return;
        }
        this.isLoading = true;
        this.authenticationService
            .login(this.loginForm.value)
            .pipe(finalize(function () {
            _this.loginForm.markAsPristine();
            _this.isLoading = false;
        }))
            .subscribe(function () { return _this.hideModal(); }, function (_a) {
            var error = _a.error;
            return (_this.error = error.error);
        });
    };
    LoginComponent.prototype.signUp = function () {
        if (this.isPage) {
            this.router.navigate(['/auth/signup']);
        }
        else {
            this.modalHolder.put(AuthModalType.SIGNUP);
        }
    };
    LoginComponent.prototype.forgotPassword = function () {
        if (this.isPage) {
            this.router.navigate(['/auth/forgot-password']);
        }
        else {
            this.modalHolder.put(AuthModalType.FORGOT);
        }
    };
    LoginComponent.prototype.hideModal = function () {
        if (!this.isPage) {
            this.modalService.hide();
        }
    };
    LoginComponent.prototype.createForm = function () {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required, emailValidator]],
            password: ['', Validators.required],
            grant_type: ['password', Validators.required]
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
