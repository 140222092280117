import { __assign, __spreadArrays } from "tslib";
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { MarketAreaMap } from 'appy-gas-core';
import { nordStreamOperatorId, pointIdsNotIncludedInPortfolio, TAPOperatorId } from '..';
import { pointToSupplementingPointMapper, supplementingPointToPointMapper, supplementingTeregaSuperPoints, supplementingTeregaSuperPointsIds } from '../constants/app-exceptions';
import * as i0 from "@angular/core";
var ProfileHelperService = /** @class */ /*@__PURE__*/ (function () {
    function ProfileHelperService() {
    }
    ProfileHelperService.filterUselessProfileInfo = function (portfolioInformation) {
        if (portfolioInformation) {
            portfolioInformation.marketAreas = portfolioInformation.marketAreas.filter(function (marketArea) {
                // tslint:disable-next-line:max-line-length
                // TODO: remove 'MarketAreaMap.NCG_H, MarketAreaMap.NCG_L, MarketAreaMap.GASPOOL_H, MarketAreaMap.GASPOOL_L' from filter before merge Germany release;
                return ![
                    MarketAreaMap.RUSSIA,
                    MarketAreaMap.ALBANIA,
                    MarketAreaMap.NCG_H,
                    MarketAreaMap.NCG_L,
                    MarketAreaMap.GASPOOL_H,
                    MarketAreaMap.GASPOOL_L
                ].includes(marketArea.marketArea.id);
            });
            portfolioInformation.operators = portfolioInformation.operators.filter(function (operator) {
                return ![nordStreamOperatorId, TAPOperatorId].includes(operator.operator.id);
            });
            // TODO: remove forEach logic before merge Germany release;
            portfolioInformation.operators.forEach(function (operator) {
                operator.pointIDs = operator.pointIDs.filter(function (pointId) { return !pointIdsNotIncludedInPortfolio.includes(pointId); });
            });
            portfolioInformation.points = portfolioInformation.points.filter(function (point) {
                return !pointIdsNotIncludedInPortfolio.includes(point.id);
            });
        }
        return portfolioInformation;
    };
    // TODO remove when super points with two operators removed
    ProfileHelperService.addSuperPointsExceptions = function (portfolioInformation) {
        return __assign(__assign({}, portfolioInformation), { superPoints: __spreadArrays(portfolioInformation.superPoints, supplementingTeregaSuperPoints), operators: portfolioInformation.operators.map(function (operator) {
                // 5 - id for Terega operator
                if (operator.operator.id === 5) {
                    return __assign(__assign({}, operator), { pointIDs: __spreadArrays(operator.pointIDs, supplementingTeregaSuperPointsIds) });
                }
                return operator;
            }), marketAreas: portfolioInformation.marketAreas.map(function (marketArea) {
                // 33 - id for PEG MA where Terega operator operates
                if (marketArea.marketArea.id === 33) {
                    return __assign(__assign({}, marketArea), { pointIDs: __spreadArrays(marketArea.pointIDs, supplementingTeregaSuperPointsIds) });
                }
                return marketArea;
            }) });
    };
    // TODO remove when super points with two operators removed
    ProfileHelperService.parseSuperPointExceptionIds = function (pointIds) {
        return uniq(pointIds.map(function (pointId) { return supplementingPointToPointMapper[pointId] || pointId; }));
    };
    // TODO remove when super points with two operators removed
    ProfileHelperService.addSuperPointExceptionIds = function (pointIds) {
        var additionalPointIds = compact(pointIds.map(function (pointId) { return pointToSupplementingPointMapper[pointId]; }));
        return __spreadArrays(pointIds, additionalPointIds);
    };
    ProfileHelperService.ɵprov = i0.ɵɵdefineInjectable({ factory: function ProfileHelperService_Factory() { return new ProfileHelperService(); }, token: ProfileHelperService, providedIn: "root" });
    return ProfileHelperService;
}());
export { ProfileHelperService };
