var SetMarketAreaViewGeneralInfo = /** @class */ /*@__PURE__*/ (function () {
    function SetMarketAreaViewGeneralInfo(marketAreaViewGeneralInfo, marketAreaPosition) {
        this.marketAreaViewGeneralInfo = marketAreaViewGeneralInfo;
        this.marketAreaPosition = marketAreaPosition;
    }
    SetMarketAreaViewGeneralInfo.type = '[Market Area View Module] Set Market Area View General Info';
    return SetMarketAreaViewGeneralInfo;
}());
export { SetMarketAreaViewGeneralInfo };
var SetMarketAreaDate = /** @class */ /*@__PURE__*/ (function () {
    function SetMarketAreaDate(date, marketAreaPosition) {
        this.date = date;
        this.marketAreaPosition = marketAreaPosition;
    }
    SetMarketAreaDate.type = '[Market Area View Module] Set Market Area Date';
    return SetMarketAreaDate;
}());
export { SetMarketAreaDate };
var GetPointDetails = /** @class */ /*@__PURE__*/ (function () {
    function GetPointDetails(virtualPointData, marketAreaPosition) {
        this.virtualPointData = virtualPointData;
        this.marketAreaPosition = marketAreaPosition;
    }
    GetPointDetails.type = '[Market Area View Module] Get Point Details';
    return GetPointDetails;
}());
export { GetPointDetails };
var ResetPointDetails = /** @class */ /*@__PURE__*/ (function () {
    function ResetPointDetails(marketAreaPosition) {
        this.marketAreaPosition = marketAreaPosition;
    }
    ResetPointDetails.type = '[Market Area View Module] Reset Point Details';
    return ResetPointDetails;
}());
export { ResetPointDetails };
var GetMarketArea = /** @class */ /*@__PURE__*/ (function () {
    function GetMarketArea(marketAreaConfig, marketAreaPosition) {
        this.marketAreaConfig = marketAreaConfig;
        this.marketAreaPosition = marketAreaPosition;
    }
    GetMarketArea.type = '[Market Area View Module] Get Market Area';
    return GetMarketArea;
}());
export { GetMarketArea };
