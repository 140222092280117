import { SwitchToExclusiveComponent } from './switch-to-exclusive.component';

export const switchToExclusiveModalConfig = {
  title: 'This feature is available for exclusive users only',
  component: {
    name: SwitchToExclusiveComponent,
    options: {}
  },
  data: {
    width: 670
  }
};
