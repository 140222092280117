import startOfDay from "date-fns/startOfDay";

import { MarketAreaMap, Role } from "appy-gas-core";
import { PointModel } from "../../profile/interfaces/point.model";
import { activatedPolandMarketAreaDate, activatedUkraineMarketAreaDate } from "./app-constant";

// TODO remove these exception when super points with two operators removed
export const superPointWithTwoOperators: { [pointId: number]: string } = {
  51035: "Terega + GRTgaz",
  51038: "Terega + GRTgaz",
  51056: "Terega + GRTgaz",
  51068: "Terega + GRTgaz",
  51071: "Terega + GRTgaz",
  51074: "Terega + GRTgaz",
  51077: "Terega + GRTgaz",
};

export const ListExceptMarketAreaView = [MarketAreaMap.RUSSIA, MarketAreaMap.NORWAY, MarketAreaMap.ALBANIA];

export const supplementingTeregaSuperPoints: PointModel[] = [
  {
    id: 100051035,
    marketAreaId: 33,
    name: "SSP NS2D",
    operatorId: 5,
    operatorType: 1,
    pointType: 11,
  },
  {
    id: 100051038,
    marketAreaId: 33,
    name: "SSP NS3D",
    operatorId: 5,
    operatorType: 1,
    pointType: 11,
  },
  {
    id: 100051056,
    marketAreaId: 33,
    name: "SSPEO2D",
    operatorId: 5,
    operatorType: 1,
    pointType: 11,
  },
  {
    id: 100051068,
    marketAreaId: 33,
    name: "SN1U",
    operatorId: 5,
    operatorType: 1,
    pointType: 11,
  },
  {
    id: 100051071,
    marketAreaId: 33,
    name: "SN2U",
    operatorId: 5,
    operatorType: 1,
    pointType: 11,
  },
  {
    id: 100051074,
    marketAreaId: 33,
    name: "SN3U",
    operatorId: 5,
    operatorType: 1,
    pointType: 11,
  },
  {
    id: 100051077,
    marketAreaId: 33,
    name: "SSPNS4D",
    operatorId: 5,
    operatorType: 1,
    pointType: 11,
  },
];
export const supplementingTeregaSuperPointsIds = supplementingTeregaSuperPoints.map(({ id }) => id);
export const pointToSupplementingPointMapper = {
  51035: 100051035,
  51038: 100051038,
  51056: 100051056,
  51068: 100051068,
  51071: 100051071,
  51074: 100051074,
  51077: 100051077,
};
export const supplementingPointToPointMapper = {
  100051035: 51035,
  100051038: 51038,
  100051056: 51056,
  100051068: 51068,
  100051071: 51071,
  100051074: 51074,
  100051077: 51077,
};

export const restrictedFeatureMAs = [
  {
    marketAreaId: MarketAreaMap.UKRAINE,
    activatedDate: startOfDay(new Date(activatedUkraineMarketAreaDate)),
    roles: [Role.PreClassic, Role.Classic, Role.TrialClassic, Role.PromoClassic, Role.PremiumPlus, Role.Premium],
    VIPIds: [
      "AG-VIP-0079",
      "AG-VIP-0080",
      "AG-VIP-0077",
      "AG-VIP-0076",
      "AG-VIP-0075",
      "AG-VIP-0078",
      "AG-VP-0010",
      "AG-VS-0016",
      "AG-VC-0017",
    ],
    pointIds: [
      21441, 21442, 21444, 21445, 21447, 21448, 21450, 21451, 21453, 21454, 21456, 21457, 21459, 21460, 21462, 21463,
      21465, 21466, 21468, 21469, 21471, 21472, 41259, 41412, 41421, 41424, 41425, 41431, 41434, 41438, 41439, 41440,
      41441, 41442, 41443, 41453, 41454, 41455, 41461, 41464, 41467, 41649,
    ],
  },
  {
    marketAreaId: MarketAreaMap.POLAND_H,
    activatedDate: startOfDay(new Date(activatedPolandMarketAreaDate)),
    roles: [Role.PreClassic, Role.Classic, Role.TrialClassic, Role.PromoClassic, Role.PremiumPlus, Role.Premium],
    // TODO: replace VIPIds by valid after Poland BE will be done;
    VIPIds: [
      "AG-VC-0018",
      "AG-VP-0011",
      "AG-VS-0017",
      "AG-LNG-0025",
      "AG-VIP-0041",
      // "AG-VIP-0054", // VIP CZECH - Poland H
      // "AG-VIP-0073", // VIP THE H - Poland H
      // "AG-VIP-0077", // VIP UKRAINE - Poland H
      "AG-VIP-0081",
      "AG-VIP-0082",
      "AG-VIP-0083",
    ],
    pointIds: [
      1075, 1076, 11062, 11065, 21477, 21478, 21480, 21481, 21483, 21484, 21676, 21677, 30074, 30075, 41257, 41283,
      41339, 41457, 41458, 41459, 41551, 41554, 41654, 41655, 41657, 41658, 41660, 41661, 41675, 41678,
    ],
  },
  {
    marketAreaId: MarketAreaMap.POLAND_L,
    activatedDate: startOfDay(new Date(activatedPolandMarketAreaDate)),
    roles: [Role.PreClassic, Role.Classic, Role.TrialClassic, Role.PromoClassic, Role.PremiumPlus, Role.Premium],
    VIPIds: ["AG-VP-0012", "AG-VC-0019"],
    pointIds: [1074, 1077, 11063, 11064],
  },
];

export const virtualPointIdsBeforeGermanyMerge = [
  "AG-VS-0006",
  "AG-VS-0009",
  "AG-VP-0006",
  "AG-VIP-0045",
  "AG-VIP-0035",
  "AG-VIP-0041",
  "AG-VIP-0043",
  "AG-VIP-0023",
  "AG-VIP-0024",
  "AG-VIP-0025",
  "AG-VIP-0059",
  "AG-VIP-0020",
  "AG-VIP-0019",
  "AG-VIP-0012",
  "AG-VIP-0010",
  "AG-VIP-0031",
  "AG-VIP-0044",
  "AG-VIP-0032",
  "AG-VIP-0030",
];

export const virtualPointIdsAfterGermanyMerge = [
  "AG-VIP-0064",
  "AG-VIP-0065",
  "AG-VIP-0066",
  "AG-VIP-0067",
  "AG-VIP-0068",
  "AG-VIP-0069",
  "AG-VIP-0070",
  "AG-VIP-0071",
  "AG-VIP-0072",
  "AG-VIP-0073",
  "AG-VIP-0074",
  "AG-VS-0018",
  "AG-VP-0013",
  "AG-LNG-0026",
  "AG-LNG-0027",
  "AG-LNG-0028"
];
