import { EventEmitter } from '@angular/core';
import { TutorialArrowSideEnum, TutorialDirectionEnum, TutorialStepEnum } from '../../../profile/enums';
var ProgressPopUpComponent = /** @class */ /*@__PURE__*/ (function () {
    function ProgressPopUpComponent() {
        this.changeStep = new EventEmitter();
        this.skipPopup = new EventEmitter();
        this.closePopup = new EventEmitter();
        this.singlePopup = false;
        this.singleSkipPopup = false;
        this.tutorialDirectionEnum = TutorialDirectionEnum;
        this.tutorialArrowSideEnum = TutorialArrowSideEnum;
    }
    Object.defineProperty(ProgressPopUpComponent.prototype, "hideTutorial", {
        set: function (value) {
            this.hiddenPopUp = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProgressPopUpComponent.prototype, "showPrevious", {
        get: function () {
            return this.stepIndex !== TutorialStepEnum.FIRST;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProgressPopUpComponent.prototype, "lastSlide", {
        get: function () {
            return this.stepIndex === TutorialStepEnum.SIXTH;
        },
        enumerable: false,
        configurable: true
    });
    ProgressPopUpComponent.prototype.skipTutorial = function (event) {
        this.hiddenPopUp = true;
        this.skipPopup.emit(true);
        event.stopPropagation();
    };
    ProgressPopUpComponent.prototype.closeTutorial = function (event) {
        this.hiddenPopUp = true;
        this.closePopup.emit(true);
        event.stopPropagation();
    };
    ProgressPopUpComponent.prototype.changeTutorialStep = function (direction) {
        this.changeStep.emit({ direction: direction, stepIndex: this.stepIndex });
    };
    return ProgressPopUpComponent;
}());
export { ProgressPopUpComponent };
