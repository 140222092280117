var GetHubPrices = /** @class */ /*@__PURE__*/ (function () {
    function GetHubPrices(hubPrices) {
        this.hubPrices = hubPrices;
    }
    GetHubPrices.type = '[Dashboard Module] Get Hub Prices';
    return GetHubPrices;
}());
export { GetHubPrices };
var GetHubPricesFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetHubPricesFailed() {
    }
    GetHubPricesFailed.type = '[Dashboard Module] Get Hub Prices Failed';
    return GetHubPricesFailed;
}());
export { GetHubPricesFailed };
var GetHubPricesLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetHubPricesLoading() {
    }
    GetHubPricesLoading.type = '[Dashboard Module] Get Hub Prices Loading';
    return GetHubPricesLoading;
}());
export { GetHubPricesLoading };
var GetHubPricesLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetHubPricesLoaded() {
    }
    GetHubPricesLoaded.type = '[Dashboard Module] Get Hub Prices Loaded';
    return GetHubPricesLoaded;
}());
export { GetHubPricesLoaded };
var GetLngCountries = /** @class */ /*@__PURE__*/ (function () {
    function GetLngCountries(lngCountries) {
        this.lngCountries = lngCountries;
    }
    GetLngCountries.type = '[Dashboard Module] Get Lng Countries';
    return GetLngCountries;
}());
export { GetLngCountries };
var GetLngCountriesFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetLngCountriesFailed() {
    }
    GetLngCountriesFailed.type = '[Dashboard Module] Get Lng Countries Failed';
    return GetLngCountriesFailed;
}());
export { GetLngCountriesFailed };
var GetLngCountriesLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetLngCountriesLoading() {
    }
    GetLngCountriesLoading.type = '[Dashboard Module] Get Lng Countries Loading';
    return GetLngCountriesLoading;
}());
export { GetLngCountriesLoading };
var GetLngCountriesLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetLngCountriesLoaded() {
    }
    GetLngCountriesLoaded.type = '[Dashboard Module] Get Lng Countries Loaded';
    return GetLngCountriesLoaded;
}());
export { GetLngCountriesLoaded };
var GetPipelineCountries = /** @class */ /*@__PURE__*/ (function () {
    function GetPipelineCountries(pipelineCountries) {
        this.pipelineCountries = pipelineCountries;
    }
    GetPipelineCountries.type = '[Dashboard Module] Get Pipeline Countries';
    return GetPipelineCountries;
}());
export { GetPipelineCountries };
var GetPipelineCountriesFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetPipelineCountriesFailed() {
    }
    GetPipelineCountriesFailed.type = '[Dashboard Module] Get Pipeline Countries Failed';
    return GetPipelineCountriesFailed;
}());
export { GetPipelineCountriesFailed };
var GetPipelineCountriesLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetPipelineCountriesLoading() {
    }
    GetPipelineCountriesLoading.type = '[Dashboard Module] Get Pipeline Countries Loading';
    return GetPipelineCountriesLoading;
}());
export { GetPipelineCountriesLoading };
var GetPipelineCountriesLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetPipelineCountriesLoaded() {
    }
    GetPipelineCountriesLoaded.type = '[Dashboard Module] Get Pipeline Countries Loaded';
    return GetPipelineCountriesLoaded;
}());
export { GetPipelineCountriesLoaded };
var GetPipelineCountriesVsLngSupply = /** @class */ /*@__PURE__*/ (function () {
    function GetPipelineCountriesVsLngSupply(pipelineCountries) {
        this.pipelineCountries = pipelineCountries;
    }
    GetPipelineCountriesVsLngSupply.type = '[Dashboard Module] Get Pipeline Countries vs LNG Supply';
    return GetPipelineCountriesVsLngSupply;
}());
export { GetPipelineCountriesVsLngSupply };
var GetPipelineCountriesVsLngSupplyFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetPipelineCountriesVsLngSupplyFailed() {
    }
    GetPipelineCountriesVsLngSupplyFailed.type = '[Dashboard Module] Get Pipeline Countries vs LNG Supply Failed';
    return GetPipelineCountriesVsLngSupplyFailed;
}());
export { GetPipelineCountriesVsLngSupplyFailed };
var GetPipelineCountriesVsLngSupplyLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetPipelineCountriesVsLngSupplyLoading() {
    }
    GetPipelineCountriesVsLngSupplyLoading.type = '[Dashboard Module] Get Pipeline Countries vs LNG Supply Loading';
    return GetPipelineCountriesVsLngSupplyLoading;
}());
export { GetPipelineCountriesVsLngSupplyLoading };
var GetPipelineCountriesVsLngSupplyLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetPipelineCountriesVsLngSupplyLoaded() {
    }
    GetPipelineCountriesVsLngSupplyLoaded.type = '[Dashboard Module] Get Pipeline Countries vs LNG Supply Loaded';
    return GetPipelineCountriesVsLngSupplyLoaded;
}());
export { GetPipelineCountriesVsLngSupplyLoaded };
var GetMaintenancePlan = /** @class */ /*@__PURE__*/ (function () {
    function GetMaintenancePlan(maintenancePlan) {
        this.maintenancePlan = maintenancePlan;
    }
    GetMaintenancePlan.type = '[Dashboard Module] Get Maintenance Plan';
    return GetMaintenancePlan;
}());
export { GetMaintenancePlan };
var GetMaintenancePlanFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetMaintenancePlanFailed() {
    }
    GetMaintenancePlanFailed.type = '[Dashboard Module] Get Maintenance Plan Failed';
    return GetMaintenancePlanFailed;
}());
export { GetMaintenancePlanFailed };
var GetMaintenancePlanLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetMaintenancePlanLoading() {
    }
    GetMaintenancePlanLoading.type = '[Dashboard Module] Get Maintenance Plan Loading';
    return GetMaintenancePlanLoading;
}());
export { GetMaintenancePlanLoading };
var GetMaintenancePlanLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetMaintenancePlanLoaded() {
    }
    GetMaintenancePlanLoaded.type = '[Dashboard Module] Get Maintenance Plan Loaded';
    return GetMaintenancePlanLoaded;
}());
export { GetMaintenancePlanLoaded };
var GetSpreads = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreads(spreads) {
        this.spreads = spreads;
    }
    GetSpreads.type = '[Dashboard Module] Get Spreads';
    return GetSpreads;
}());
export { GetSpreads };
var GetSpreadsFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreadsFailed() {
    }
    GetSpreadsFailed.type = '[Dashboard Module] Get Spreads Failed';
    return GetSpreadsFailed;
}());
export { GetSpreadsFailed };
var GetSpreadsLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreadsLoading() {
    }
    GetSpreadsLoading.type = '[Dashboard Module] Get Spreads Loading';
    return GetSpreadsLoading;
}());
export { GetSpreadsLoading };
var GetSpreadsLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreadsLoaded() {
    }
    GetSpreadsLoaded.type = '[Dashboard Module] Get Spreads Loaded';
    return GetSpreadsLoaded;
}());
export { GetSpreadsLoaded };
var GetAllFilterSpreads = /** @class */ /*@__PURE__*/ (function () {
    function GetAllFilterSpreads(allFilterSpreads) {
        this.allFilterSpreads = allFilterSpreads;
    }
    GetAllFilterSpreads.type = '[Dashboard Module] Get All Filter Spreads';
    return GetAllFilterSpreads;
}());
export { GetAllFilterSpreads };
var GetAllFilterSpreadsFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetAllFilterSpreadsFailed() {
    }
    GetAllFilterSpreadsFailed.type = '[Dashboard Module] Get All Filter Spreads Failed';
    return GetAllFilterSpreadsFailed;
}());
export { GetAllFilterSpreadsFailed };
var GetAllFilterSpreadsLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetAllFilterSpreadsLoading() {
    }
    GetAllFilterSpreadsLoading.type = '[Dashboard Module] Get All Filter Spreads Loading';
    return GetAllFilterSpreadsLoading;
}());
export { GetAllFilterSpreadsLoading };
var GetAllFilterSpreadsLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetAllFilterSpreadsLoaded() {
    }
    GetAllFilterSpreadsLoaded.type = '[Dashboard Module] Get All Filter Spreads Loaded';
    return GetAllFilterSpreadsLoaded;
}());
export { GetAllFilterSpreadsLoaded };
var GetPreviewSpreads = /** @class */ /*@__PURE__*/ (function () {
    function GetPreviewSpreads(previewSpreads) {
        this.previewSpreads = previewSpreads;
    }
    GetPreviewSpreads.type = '[Dashboard Module] Get Preview Spreads';
    return GetPreviewSpreads;
}());
export { GetPreviewSpreads };
var GetPreviewSpreadsFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetPreviewSpreadsFailed() {
    }
    GetPreviewSpreadsFailed.type = '[Dashboard Module] Get Preview Spreads Failed';
    return GetPreviewSpreadsFailed;
}());
export { GetPreviewSpreadsFailed };
var GetPreviewSpreadsLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetPreviewSpreadsLoading() {
    }
    GetPreviewSpreadsLoading.type = '[Dashboard Module] Get Preview Spreads Loading';
    return GetPreviewSpreadsLoading;
}());
export { GetPreviewSpreadsLoading };
var GetPreviewSpreadsLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetPreviewSpreadsLoaded() {
    }
    GetPreviewSpreadsLoaded.type = '[Dashboard Module] Get Preview Spreads Loaded';
    return GetPreviewSpreadsLoaded;
}());
export { GetPreviewSpreadsLoaded };
var GetSpreadFilters = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreadFilters(spreadFilters) {
        this.spreadFilters = spreadFilters;
    }
    GetSpreadFilters.type = '[Dashboard Module] Get Spread Filters';
    return GetSpreadFilters;
}());
export { GetSpreadFilters };
var GetSpreadFiltersFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreadFiltersFailed() {
    }
    GetSpreadFiltersFailed.type = '[Dashboard Module] Get Spread Filters Failed';
    return GetSpreadFiltersFailed;
}());
export { GetSpreadFiltersFailed };
var GetSpreadFiltersLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreadFiltersLoading() {
    }
    GetSpreadFiltersLoading.type = '[Dashboard Module] Get Spread Filters Loading';
    return GetSpreadFiltersLoading;
}());
export { GetSpreadFiltersLoading };
var GetSpreadFiltersLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreadFiltersLoaded() {
    }
    GetSpreadFiltersLoaded.type = '[Dashboard Module] Get Spread Filters Loaded';
    return GetSpreadFiltersLoaded;
}());
export { GetSpreadFiltersLoaded };
var GetSpreadMarketAreas = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreadMarketAreas(spreadMarketAreas) {
        this.spreadMarketAreas = spreadMarketAreas;
    }
    GetSpreadMarketAreas.type = '[Dashboard Module] Get Spread Market Areas';
    return GetSpreadMarketAreas;
}());
export { GetSpreadMarketAreas };
var GetSpreadMarketAreasFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreadMarketAreasFailed() {
    }
    GetSpreadMarketAreasFailed.type = '[Dashboard Module] Get Spread Market Areas Failed';
    return GetSpreadMarketAreasFailed;
}());
export { GetSpreadMarketAreasFailed };
var GetSpreadMarketAreasLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreadMarketAreasLoading() {
    }
    GetSpreadMarketAreasLoading.type = '[Dashboard Module] Get Spread Market Areas Loading';
    return GetSpreadMarketAreasLoading;
}());
export { GetSpreadMarketAreasLoading };
var GetSpreadMarketAreasLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetSpreadMarketAreasLoaded() {
    }
    GetSpreadMarketAreasLoaded.type = '[Dashboard Module] Get Spread Market Areas Loaded';
    return GetSpreadMarketAreasLoaded;
}());
export { GetSpreadMarketAreasLoaded };
var GetMaintenanceUpdate = /** @class */ /*@__PURE__*/ (function () {
    function GetMaintenanceUpdate() {
    }
    GetMaintenanceUpdate.type = '[Dashboard Module] Get Maintenance Update';
    return GetMaintenanceUpdate;
}());
export { GetMaintenanceUpdate };
var GetFullSpreadFilter = /** @class */ /*@__PURE__*/ (function () {
    function GetFullSpreadFilter(spreadFilterForEdit) {
        this.spreadFilterForEdit = spreadFilterForEdit;
    }
    GetFullSpreadFilter.type = '[Dashboard Module] Get Full Spread Filter';
    return GetFullSpreadFilter;
}());
export { GetFullSpreadFilter };
var GetFullSpreadFilterFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetFullSpreadFilterFailed() {
    }
    GetFullSpreadFilterFailed.type = '[Dashboard Module] Get Full Spread Filter Failed';
    return GetFullSpreadFilterFailed;
}());
export { GetFullSpreadFilterFailed };
var GetFullSpreadFilterLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetFullSpreadFilterLoading() {
    }
    GetFullSpreadFilterLoading.type = '[Dashboard Module] Get Full Spread Filter Loading';
    return GetFullSpreadFilterLoading;
}());
export { GetFullSpreadFilterLoading };
var GetFullSpreadFilterLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetFullSpreadFilterLoaded() {
    }
    GetFullSpreadFilterLoaded.type = '[Dashboard Module] Get Full Spread Filter Loaded';
    return GetFullSpreadFilterLoaded;
}());
export { GetFullSpreadFilterLoaded };
var DeleteFullSpreadFilter = /** @class */ /*@__PURE__*/ (function () {
    function DeleteFullSpreadFilter() {
    }
    DeleteFullSpreadFilter.type = '[Dashboard Module] Delete Full Spread Filter';
    return DeleteFullSpreadFilter;
}());
export { DeleteFullSpreadFilter };
var GetMaintenanceUpdateSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetMaintenanceUpdateSuccess(maintenanceUpdate) {
        this.maintenanceUpdate = maintenanceUpdate;
    }
    GetMaintenanceUpdateSuccess.type = '[Dashboard Module] Get Maintenance Update Success';
    return GetMaintenanceUpdateSuccess;
}());
export { GetMaintenanceUpdateSuccess };
var GetMaintenanceUpdateFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetMaintenanceUpdateFailed() {
    }
    GetMaintenanceUpdateFailed.type = '[Dashboard Module] Get Maintenance Update Failed';
    return GetMaintenanceUpdateFailed;
}());
export { GetMaintenanceUpdateFailed };
var GetLastAuctionStats = /** @class */ /*@__PURE__*/ (function () {
    function GetLastAuctionStats(lastAuctionStats) {
        this.lastAuctionStats = lastAuctionStats;
    }
    GetLastAuctionStats.type = '[Dashboard Module] Get Last Auction Stats';
    return GetLastAuctionStats;
}());
export { GetLastAuctionStats };
var GetLastAuctionStatsFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetLastAuctionStatsFailed() {
    }
    GetLastAuctionStatsFailed.type = '[Dashboard Module] Get Last Auction Stats Failed';
    return GetLastAuctionStatsFailed;
}());
export { GetLastAuctionStatsFailed };
var GetLastAuctionStatsLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetLastAuctionStatsLoading() {
    }
    GetLastAuctionStatsLoading.type = '[Dashboard Module] Get Last Auction Stats Loading';
    return GetLastAuctionStatsLoading;
}());
export { GetLastAuctionStatsLoading };
var GetLastAuctionStatsLoaded = /** @class */ /*@__PURE__*/ (function () {
    function GetLastAuctionStatsLoaded() {
    }
    GetLastAuctionStatsLoaded.type = '[Dashboard Module] Get Last Auction Stats Loaded';
    return GetLastAuctionStatsLoaded;
}());
export { GetLastAuctionStatsLoaded };
var GetBlockPositions = /** @class */ /*@__PURE__*/ (function () {
    function GetBlockPositions(blockPositions) {
        this.blockPositions = blockPositions;
    }
    GetBlockPositions.type = '[Dashboard Module] Get Block Positions';
    return GetBlockPositions;
}());
export { GetBlockPositions };
