import { format } from 'date-fns';
import { ModalService } from 'appy-gas-core';
var RestrictedMarketAreaClassicComponent = /** @class */ /*@__PURE__*/ (function () {
    function RestrictedMarketAreaClassicComponent(modalService) {
        this.modalService = modalService;
    }
    Object.defineProperty(RestrictedMarketAreaClassicComponent.prototype, "activatedDateMonth", {
        get: function () {
            return format(this.activatedDate, 'MMMM');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RestrictedMarketAreaClassicComponent.prototype, "activatedDateYear", {
        get: function () {
            return format(this.activatedDate, 'yyyy');
        },
        enumerable: false,
        configurable: true
    });
    RestrictedMarketAreaClassicComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    RestrictedMarketAreaClassicComponent.prototype.switchToExclusive = function () {
        this.modalService.hide();
        this.proceedToNextStep();
    };
    return RestrictedMarketAreaClassicComponent;
}());
export { RestrictedMarketAreaClassicComponent };
