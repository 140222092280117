export var LocalStorageKeysEnum = /*@__PURE__*/ (function (LocalStorageKeysEnum) {
    LocalStorageKeysEnum["DO_NOT_SHOW_FEATURES_PRIVACY_POLICY_POP_UP"] = "doNotShowFeaturesPrivacyPolicyPopUp";
    LocalStorageKeysEnum["DO_NOT_SHOW_ROUTE_CALCULATOR_PRIVACY_POLICY_POP_UP"] = "doNotShowRouteCalculatorPrivacyPolicyPopUp";
    LocalStorageKeysEnum["DO_NOT_SHOW_PORTFOLIO_POP_UP_ON_MAP"] = "doNotShowPortfolioPopUpOnMap";
    LocalStorageKeysEnum["DO_NOT_SHOW_NEW_FEATURES_MODAL"] = "doNotShowTHECommercialVideoModal";
    LocalStorageKeysEnum["USER_ROLE_INFO_MODAL"] = "userRoleInfoModal";
    LocalStorageKeysEnum["USER_ROLE_EXCLUSIVE_MODAL"] = "userRoleExclusiveModal";
    LocalStorageKeysEnum["MERGED_GERMANY_POP_UP"] = "mergedGermanyPopUp";
    LocalStorageKeysEnum["USER_SELECTED_HUB_PRICES_LEGEND"] = "USER_SELECTED_HUB_PRICES_LEGEND";
    return LocalStorageKeysEnum;
})({});
