import { TrialExclusiveAccessExpiredComponent } from './trial-exclusive-access-expired.component';

export const trialExclusiveAccessExpiredConfig = {
  title: '',
  component: {
    name: TrialExclusiveAccessExpiredComponent,
    options: {}
  },
  data: {
    width: 650
  }
};
