import { Router } from '@angular/router';
var RestrictElementComponent = /** @class */ /*@__PURE__*/ (function () {
    function RestrictElementComponent(router) {
        this.router = router;
        this.callToAction = true;
    }
    RestrictElementComponent.prototype.upgradePremium = function () {
        this.router.navigate(['/static/contact-us/upgrade-exclusive']);
    };
    return RestrictElementComponent;
}());
export { RestrictElementComponent };
