import { UnitModel } from '../interfaces';

export const units: UnitModel[] = [
  { id: 1, abbreviaton: 'kWh', longName: 'kilowatt hour' },
  { id: 2, abbreviaton: 'MWh', longName: 'megawatt hour' },
  { id: 3, abbreviaton: 'GWh', longName: 'gigawatt hour' },
  { id: 4, abbreviaton: 'TWh', longName: 'terawatt hour' },
  { id: 5, abbreviaton: 'Nm^3', longName: 'normal cubic meter' },
  { id: 6, abbreviaton: '10^6*Nm^3', longName: '1 Mio. normal cubic meter' },
  { id: 7, abbreviaton: 'm^3', longName: 'cubic meter' },
  { id: 8, abbreviaton: '10^3*m^3', longName: '1000 cubic meter' },
  { id: 9, abbreviaton: '10^6*m^3', longName: '1 Mio. cubic meter' },
  { id: 10, abbreviaton: 'kWh/h', longName: 'kilowatt hour per hour' },
  { id: 11, abbreviaton: 'MWh/h', longName: 'megawatt hour per hour' },
  { id: 12, abbreviaton: 'GWh/h', longName: 'gigawatt hour per hour' },
  { id: 13, abbreviaton: 'm^3/h', longName: 'cubic meter per hour' },
  { id: 14, abbreviaton: 'Nm^3/h', longName: 'normal cubic meter per hour' },
  { id: 15, abbreviaton: 'kWh/d', longName: 'kilowatt hour per day' },
  { id: 16, abbreviaton: 'MWh/d', longName: 'megawatt hour per day' },
  { id: 17, abbreviaton: 'GWh/d', longName: 'gigawatt hour per day' },
  { id: 18, abbreviaton: 'Mcm/d', longName: '1 Mio. cubic meter per day' },
  { id: 19, abbreviaton: 'MSm^3/d', longName: '1 Mio.\u00A0 standard cubic metres per day' },
  { id: 20, abbreviaton: 'kWh/m^3', longName: 'kilowatt hour per cubic meter' },
  { id: 21, abbreviaton: 'kWh/Nm^3', longName: 'kilowatt hour per normal cubic meter' },
  { id: 22, abbreviaton: '%', longName: 'percentage' },
  { id: 23, abbreviaton: 'Eur/MWh', longName: 'Euro per megawatt hour' },
  { id: 24, abbreviaton: 'MW', longName: 'megawatt' },
  { id: 25, abbreviaton: 'p/Th', longName: 'Pence per Therm' },
  { id: 26, abbreviaton: 'm^3 (LNG)', longName: 'cubic meter LNG' },
  { id: 27, abbreviaton: 'String', longName: 'Text value' },
  { id: 28, abbreviaton: '10^3*m^3 (LNG)', longName: '1000 cubic meter LNG' },
  { id: 29, abbreviaton: '€/(kWh/h)/d', longName: 'Euro per KWh per hour per day' },
  { id: 30, abbreviaton: 'c€/(kWh/h)/runtime', longName: 'cent Euro per kWh/h per hour per runtime' },
  { id: 31, abbreviaton: 'pence/(kWh/h)/d', longName: 'Pence per KWh per hour per day' },
  { id: 32, abbreviaton: 'pence/(kWh/h)/runtime', longName: 'pence per kWh/h per hour per runtime' },
  { id: 33, abbreviaton: '€', longName: 'Euro' },
  { id: 34, abbreviaton: 'c€', longName: 'cent Euro' },
  { id: 35, abbreviaton: 'pence', longName: 'Pence' },
  { id: 36, abbreviaton: '£', longName: 'Pound' },
  { id: 37, abbreviaton: 'Nm^3/d', longName: 'normal cubic meter per day' },
  { id: 38, abbreviaton: 'halér/kWh/h/Runtime', longName: 'Haler per kWh/h per hour per runtime' },
  { id: 39, abbreviaton: 'CZK/(kWh/h)/d', longName: 'Czech koruna per kWh per hour per day' },
  { id: 40, abbreviaton: 'GBP/(kWh/h)/d', longName: 'Great Britain pound per kWh per hour per day' },
  { id: 41, abbreviaton: 'EUR/(kWh/h)/d', longName: 'European euro per kWh per hour per day' },
  { id: 42, abbreviaton: 'CZK', longName: 'ChechKoruna' }
];
