var GetAllMaintenancePoints = /** @class */ /*@__PURE__*/ (function () {
    function GetAllMaintenancePoints(allMaintenancePoints) {
        this.allMaintenancePoints = allMaintenancePoints;
    }
    GetAllMaintenancePoints.type = '[Availability Module] Get All Virtual Points';
    return GetAllMaintenancePoints;
}());
export { GetAllMaintenancePoints };
var GetAllEventPoints = /** @class */ /*@__PURE__*/ (function () {
    function GetAllEventPoints() {
    }
    GetAllEventPoints.type = '[Availability Module] Get All Event Points';
    return GetAllEventPoints;
}());
export { GetAllEventPoints };
var GetAllEventPointsSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetAllEventPointsSuccess(allEventPoints) {
        this.allEventPoints = allEventPoints;
    }
    GetAllEventPointsSuccess.type = '[Availability Module] Get All Event Points Success';
    return GetAllEventPointsSuccess;
}());
export { GetAllEventPointsSuccess };
var GetAllEventPointsFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetAllEventPointsFailed() {
    }
    GetAllEventPointsFailed.type = '[Availability Module] Get All Event Points Failed';
    return GetAllEventPointsFailed;
}());
export { GetAllEventPointsFailed };
var GetPortfolioMaintenancePoints = /** @class */ /*@__PURE__*/ (function () {
    function GetPortfolioMaintenancePoints() {
    }
    GetPortfolioMaintenancePoints.type = '[Availability Module] Get Portfolio Virtual Points';
    return GetPortfolioMaintenancePoints;
}());
export { GetPortfolioMaintenancePoints };
var ResetPortfolioMaintenancePoints = /** @class */ /*@__PURE__*/ (function () {
    function ResetPortfolioMaintenancePoints() {
    }
    ResetPortfolioMaintenancePoints.type = '[Availability Module] Reset Portfolio Virtual Points';
    return ResetPortfolioMaintenancePoints;
}());
export { ResetPortfolioMaintenancePoints };
var GetPortfolioMaintenancePointsSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetPortfolioMaintenancePointsSuccess(portfolioMaintenancePoints) {
        this.portfolioMaintenancePoints = portfolioMaintenancePoints;
    }
    GetPortfolioMaintenancePointsSuccess.type = '[Availability Module] Get Portfolio Virtual Points Success';
    return GetPortfolioMaintenancePointsSuccess;
}());
export { GetPortfolioMaintenancePointsSuccess };
var GetPortfolioMaintenancePointsFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetPortfolioMaintenancePointsFailed() {
    }
    GetPortfolioMaintenancePointsFailed.type = '[Availability Module] Get Portfolio Virtual Points Failed';
    return GetPortfolioMaintenancePointsFailed;
}());
export { GetPortfolioMaintenancePointsFailed };
var GetAvailabilityData = /** @class */ /*@__PURE__*/ (function () {
    function GetAvailabilityData(availabilityData) {
        this.availabilityData = availabilityData;
    }
    GetAvailabilityData.type = '[Availability Module] Get Availability Data';
    return GetAvailabilityData;
}());
export { GetAvailabilityData };
var UpdateMaintenanceFilters = /** @class */ /*@__PURE__*/ (function () {
    function UpdateMaintenanceFilters(maintenanceFilters) {
        this.maintenanceFilters = maintenanceFilters;
    }
    UpdateMaintenanceFilters.type = '[Availability Module] Update Maintenance Filters';
    return UpdateMaintenanceFilters;
}());
export { UpdateMaintenanceFilters };
var GetSelectedPointEvents = /** @class */ /*@__PURE__*/ (function () {
    function GetSelectedPointEvents() {
    }
    GetSelectedPointEvents.type = '[Availability Module] Get Selected Point Events';
    return GetSelectedPointEvents;
}());
export { GetSelectedPointEvents };
var ResetSelectedPointEvents = /** @class */ /*@__PURE__*/ (function () {
    function ResetSelectedPointEvents() {
    }
    ResetSelectedPointEvents.type = '[Availability Module] Reset Selected Point Events';
    return ResetSelectedPointEvents;
}());
export { ResetSelectedPointEvents };
var GetSelectedPointEventsSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetSelectedPointEventsSuccess(selectedPointEvents, vipPointMarketAreasIds) {
        this.selectedPointEvents = selectedPointEvents;
        this.vipPointMarketAreasIds = vipPointMarketAreasIds;
    }
    GetSelectedPointEventsSuccess.type = '[Availability Module] Get Selected Point Events Success';
    return GetSelectedPointEventsSuccess;
}());
export { GetSelectedPointEventsSuccess };
var GetSelectedPointEventsFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetSelectedPointEventsFailed() {
    }
    GetSelectedPointEventsFailed.type = '[Availability Module] Get Selected Point Events Failed';
    return GetSelectedPointEventsFailed;
}());
export { GetSelectedPointEventsFailed };
var SetSelectedMapPointId = /** @class */ /*@__PURE__*/ (function () {
    function SetSelectedMapPointId(selectedMapPointId) {
        this.selectedMapPointId = selectedMapPointId;
    }
    SetSelectedMapPointId.type = '[Availability Module] Set Selected Map Point Id';
    return SetSelectedMapPointId;
}());
export { SetSelectedMapPointId };
var UpdateSelectedPointEventsFilter = /** @class */ /*@__PURE__*/ (function () {
    function UpdateSelectedPointEventsFilter(eventsWidgetFilter) {
        this.eventsWidgetFilter = eventsWidgetFilter;
    }
    UpdateSelectedPointEventsFilter.type = '[Availability Module] Update Selected Point Events Filter';
    return UpdateSelectedPointEventsFilter;
}());
export { UpdateSelectedPointEventsFilter };
var GetSelectedNorwayEvents = /** @class */ /*@__PURE__*/ (function () {
    function GetSelectedNorwayEvents(selectedPointEvents, vipPointMarketAreasIds) {
        this.selectedPointEvents = selectedPointEvents;
        this.vipPointMarketAreasIds = vipPointMarketAreasIds;
    }
    GetSelectedNorwayEvents.type = '[Availability Module] Get Selected Norway Events';
    return GetSelectedNorwayEvents;
}());
export { GetSelectedNorwayEvents };
var GetSelectedPEGSuperPointsEvents = /** @class */ /*@__PURE__*/ (function () {
    function GetSelectedPEGSuperPointsEvents(selectedPointEvents, vipPointMarketAreasIds) {
        this.selectedPointEvents = selectedPointEvents;
        this.vipPointMarketAreasIds = vipPointMarketAreasIds;
    }
    GetSelectedPEGSuperPointsEvents.type = '[Availability Module] Get Selected PEG Super Points Events';
    return GetSelectedPEGSuperPointsEvents;
}());
export { GetSelectedPEGSuperPointsEvents };
var GetSelectedRouteDetails = /** @class */ /*@__PURE__*/ (function () {
    function GetSelectedRouteDetails() {
    }
    GetSelectedRouteDetails.type = '[Availability Module] Get Selected Route Details';
    return GetSelectedRouteDetails;
}());
export { GetSelectedRouteDetails };
var ResetSelectedRouteDetails = /** @class */ /*@__PURE__*/ (function () {
    function ResetSelectedRouteDetails() {
    }
    ResetSelectedRouteDetails.type = '[Availability Module] Reset Selected Route Details';
    return ResetSelectedRouteDetails;
}());
export { ResetSelectedRouteDetails };
var GetSelectedRouteDetailsSuccess = /** @class */ /*@__PURE__*/ (function () {
    function GetSelectedRouteDetailsSuccess(selectedRouteEvents) {
        this.selectedRouteEvents = selectedRouteEvents;
    }
    GetSelectedRouteDetailsSuccess.type = '[Availability Module] Get Selected Route Details Success';
    return GetSelectedRouteDetailsSuccess;
}());
export { GetSelectedRouteDetailsSuccess };
var GetSelectedRouteDetailsFailed = /** @class */ /*@__PURE__*/ (function () {
    function GetSelectedRouteDetailsFailed() {
    }
    GetSelectedRouteDetailsFailed.type = '[Availability Module] Get Selected Route Details Failed';
    return GetSelectedRouteDetailsFailed;
}());
export { GetSelectedRouteDetailsFailed };
var GetRelatedPointsLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetRelatedPointsLoading(pointId) {
        this.pointId = pointId;
    }
    GetRelatedPointsLoading.type = '[Availability Module] Get Related Points Loading';
    return GetRelatedPointsLoading;
}());
export { GetRelatedPointsLoading };
var GetRelatedPoints = /** @class */ /*@__PURE__*/ (function () {
    function GetRelatedPoints(relatedPoints, pointId) {
        this.relatedPoints = relatedPoints;
        this.pointId = pointId;
    }
    GetRelatedPoints.type = '[Availability Module] Get Related Points';
    return GetRelatedPoints;
}());
export { GetRelatedPoints };
var GetEventDetailsLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetEventDetailsLoading(pointId) {
        this.pointId = pointId;
    }
    GetEventDetailsLoading.type = '[Availability Module] Get Event Details Loading';
    return GetEventDetailsLoading;
}());
export { GetEventDetailsLoading };
var GetEventDetails = /** @class */ /*@__PURE__*/ (function () {
    function GetEventDetails(eventDetails, pointId) {
        this.eventDetails = eventDetails;
        this.pointId = pointId;
    }
    GetEventDetails.type = '[Availability Module] Get Event Details';
    return GetEventDetails;
}());
export { GetEventDetails };
var GetOtherEventsLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetOtherEventsLoading(pointId) {
        this.pointId = pointId;
    }
    GetOtherEventsLoading.type = '[Availability Module] Get Other Events Loading';
    return GetOtherEventsLoading;
}());
export { GetOtherEventsLoading };
var GetOtherEvents = /** @class */ /*@__PURE__*/ (function () {
    function GetOtherEvents(otherEvents, pointId) {
        this.otherEvents = otherEvents;
        this.pointId = pointId;
    }
    GetOtherEvents.type = '[Availability Module] Get Other Events';
    return GetOtherEvents;
}());
export { GetOtherEvents };
var GetImpactChartData = /** @class */ /*@__PURE__*/ (function () {
    function GetImpactChartData(impactChartData, pointId) {
        this.impactChartData = impactChartData;
        this.pointId = pointId;
    }
    GetImpactChartData.type = '[Availability Module] Get Impact Chart Data';
    return GetImpactChartData;
}());
export { GetImpactChartData };
var GetImpactChartDataLoading = /** @class */ /*@__PURE__*/ (function () {
    function GetImpactChartDataLoading(pointId) {
        this.pointId = pointId;
    }
    GetImpactChartDataLoading.type = '[Availability Module] Get Impact Chart Data Loading';
    return GetImpactChartDataLoading;
}());
export { GetImpactChartDataLoading };
var TogglePointInRoute = /** @class */ /*@__PURE__*/ (function () {
    function TogglePointInRoute(virtualPoint) {
        this.virtualPoint = virtualPoint;
    }
    TogglePointInRoute.type = '[Availability Module] Toggle Point To Route';
    return TogglePointInRoute;
}());
export { TogglePointInRoute };
var ResetSelectedRoutePoints = /** @class */ /*@__PURE__*/ (function () {
    function ResetSelectedRoutePoints() {
    }
    ResetSelectedRoutePoints.type = '[Availability Module] Reset Selected Route Points';
    return ResetSelectedRoutePoints;
}());
export { ResetSelectedRoutePoints };
var ChangeAvailabilityPointTypes = /** @class */ /*@__PURE__*/ (function () {
    function ChangeAvailabilityPointTypes(pointsSwitcher) {
        this.pointsSwitcher = pointsSwitcher;
    }
    ChangeAvailabilityPointTypes.type = '[Availability Module] Change Availability Point Types';
    return ChangeAvailabilityPointTypes;
}());
export { ChangeAvailabilityPointTypes };
var UpdateGasDayRange = /** @class */ /*@__PURE__*/ (function () {
    function UpdateGasDayRange(gasDayRange) {
        this.gasDayRange = gasDayRange;
    }
    UpdateGasDayRange.type = '[Availability Module] Update Gas Day Range';
    return UpdateGasDayRange;
}());
export { UpdateGasDayRange };
var UpdateWidgetsVisibilityState = /** @class */ /*@__PURE__*/ (function () {
    function UpdateWidgetsVisibilityState(selectedWidget) {
        this.selectedWidget = selectedWidget;
    }
    UpdateWidgetsVisibilityState.type = '[Availability Module] Update Widgets Visibility State';
    return UpdateWidgetsVisibilityState;
}());
export { UpdateWidgetsVisibilityState };
var UpdateActiveMATabType = /** @class */ /*@__PURE__*/ (function () {
    function UpdateActiveMATabType(activeMATabType) {
        this.activeMATabType = activeMATabType;
    }
    UpdateActiveMATabType.type = '[Availability Module] Update Active MA Tab Type ';
    return UpdateActiveMATabType;
}());
export { UpdateActiveMATabType };
var SetNavigatedFromExternalPageStatus = /** @class */ /*@__PURE__*/ (function () {
    function SetNavigatedFromExternalPageStatus(isNavigatedFromExternalPage) {
        this.isNavigatedFromExternalPage = isNavigatedFromExternalPage;
    }
    SetNavigatedFromExternalPageStatus.type = '[Availability Module] Set Navigated From External Page Status';
    return SetNavigatedFromExternalPageStatus;
}());
export { SetNavigatedFromExternalPageStatus };
var SetNavigatedFromGeneralInfoPageStatus = /** @class */ /*@__PURE__*/ (function () {
    function SetNavigatedFromGeneralInfoPageStatus(isNavigatedFromGeneralInfoPage) {
        this.isNavigatedFromGeneralInfoPage = isNavigatedFromGeneralInfoPage;
    }
    SetNavigatedFromGeneralInfoPageStatus.type = '[Availability Module] Set Navigated From General Info Page Status';
    return SetNavigatedFromGeneralInfoPageStatus;
}());
export { SetNavigatedFromGeneralInfoPageStatus };
var SetNorwayPointSelectedStatus = /** @class */ /*@__PURE__*/ (function () {
    function SetNorwayPointSelectedStatus(isNorwayPointSelected) {
        this.isNorwayPointSelected = isNorwayPointSelected;
    }
    SetNorwayPointSelectedStatus.type = '[Availability Module] Set Norway Point Selected Status';
    return SetNorwayPointSelectedStatus;
}());
export { SetNorwayPointSelectedStatus };
