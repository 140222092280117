import { HttpHeaders } from '@angular/common/http';

export interface HeaderMetaDataOptions {
  auth?: boolean;
  noLoader?: boolean;
  cache?: boolean;
  refreshCache?: boolean;
  cacheMaxAge?: number;
}

export const getMetadataHeaders = (headersOptions: HeaderMetaDataOptions): HttpHeaders => {
  return new HttpHeaders({ 'X-META-DATA': JSON.stringify(headersOptions) });
};
