export var PointTypesEnum = /*@__PURE__*/ (function (PointTypesEnum) {
    PointTypesEnum[PointTypesEnum["PRODUCTION"] = 1] = "PRODUCTION";
    PointTypesEnum[PointTypesEnum["CONSUMPTION"] = 2] = "CONSUMPTION";
    PointTypesEnum[PointTypesEnum["IP"] = 3] = "IP";
    PointTypesEnum[PointTypesEnum["STORAGE"] = 4] = "STORAGE";
    PointTypesEnum[PointTypesEnum["LNG"] = 5] = "LNG";
    PointTypesEnum[PointTypesEnum["VIP"] = 6] = "VIP";
    PointTypesEnum[PointTypesEnum["VIRTUAL_STORAGE"] = 7] = "VIRTUAL_STORAGE";
    PointTypesEnum[PointTypesEnum["VIRTUAL_PRODUCTION"] = 8] = "VIRTUAL_PRODUCTION";
    PointTypesEnum[PointTypesEnum["VIRTUAL_CONSUMPTION"] = 9] = "VIRTUAL_CONSUMPTION";
    PointTypesEnum[PointTypesEnum["SUPER_POINT"] = 10] = "SUPER_POINT";
    PointTypesEnum[PointTypesEnum["SUPER_POINT_REAL"] = 11] = "SUPER_POINT_REAL";
    return PointTypesEnum;
})({});
