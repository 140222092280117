import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService } from 'appy-gas-core';
var AccessExpiredComponent = /** @class */ /*@__PURE__*/ (function () {
    function AccessExpiredComponent(modalService, formBuilder, router) {
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.router = router;
        this.componentDestroyed$ = new Subject();
    }
    AccessExpiredComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.newsLetterForm = this.createNewsLetterForm();
        this.newsLetterForm
            .get('signUpNewsLetter')
            .valueChanges.pipe(takeUntil(this.componentDestroyed$))
            .subscribe(function (val) { return _this.syncNewsLetterSubscription(val); });
    };
    AccessExpiredComponent.prototype.ngOnDestroy = function () {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    };
    AccessExpiredComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    AccessExpiredComponent.prototype.goToContactUsPage = function () {
        var _this = this;
        this.router.navigateByUrl('static/contact-us/upgrade-exclusive').then(function () { return _this.hideModal(); });
    };
    AccessExpiredComponent.prototype.goToMyProfile = function () {
        var _this = this;
        this.router.navigateByUrl('profile/information').then(function () { return _this.hideModal(); });
    };
    AccessExpiredComponent.prototype.createNewsLetterForm = function () {
        return this.formBuilder.group({
            signUpNewsLetter: [this.newsLetterValue || false]
        });
    };
    return AccessExpiredComponent;
}());
export { AccessExpiredComponent };
