import { ModalService } from 'appy-gas-core';
var RestrictedMarketAreaPremiumComponent = /** @class */ /*@__PURE__*/ (function () {
    function RestrictedMarketAreaPremiumComponent(modalService) {
        this.modalService = modalService;
    }
    RestrictedMarketAreaPremiumComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    return RestrictedMarketAreaPremiumComponent;
}());
export { RestrictedMarketAreaPremiumComponent };
