import { TermsOfServiceUpdateComponent } from './terms-of-service-update.component';

export const termsOfServiceUpdateConfig = {
  title: '',
  component: {
    name: TermsOfServiceUpdateComponent,
    options: {
    }
  },
  data: {
    width: 650,
    closable: false
  }
};
