var APPYGAS_COOKIES_KEY = 'APPYGAS_COOKIES';
var CookiesPopoverService = /** @class */ /*@__PURE__*/ (function () {
    function CookiesPopoverService() {
    }
    CookiesPopoverService.prototype.isCookiesAccepted = function () {
        return JSON.parse(localStorage.getItem(APPYGAS_COOKIES_KEY));
    };
    CookiesPopoverService.prototype.acceptCookies = function () {
        localStorage.setItem(APPYGAS_COOKIES_KEY, JSON.stringify(true));
    };
    CookiesPopoverService.prototype.setCookies = function (key, value, time) {
        var d = new Date();
        d.setTime(d.getTime() + time);
        var expires = 'expires=' + d.toUTCString();
        document.cookie = key + '=' + value + ';' + expires + ';path=/';
    };
    CookiesPopoverService.prototype.getCookies = function (key) {
        var name = key + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var _i = 0, ca_1 = ca; _i < ca_1.length; _i++) {
            var c = ca_1[_i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    };
    return CookiesPopoverService;
}());
export { CookiesPopoverService };
