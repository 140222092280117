declare global {
  interface Window {
    Usersnap: any;
    onUsersnapLoad: any;
    onUsersnapCXLoad: any;
  }
}

export function userSnapGlobalInit(): void {
  window.onUsersnapCXLoad = (api) => {
    api.init({
      colors: {
        primary: '#7200da'
      },
      fields: {
        email: {
          label: 'Your e-mail',
          required: true
        },
        comment: {
          label: 'Your comment',
          required: false
        }
      }
    });
    window.Usersnap = api;
  };

  try {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://widget.usersnap.com/load/713adf2f-e918-4cb2-8fce-ae06ebef3bf6?onload=onUsersnapCXLoad';

    document.getElementsByTagName('head')[0].appendChild(script);
  } catch (userSnapError) {
    console.error(`USERSNAP Widget Error: ${userSnapError}`);
  }
}
