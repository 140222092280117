import { ExtendPremiumComponent } from './extend-premium.component';

export const extendPremiumModalConfig = {
  title: 'Upgrade to download functionality',
  component: {
    name: ExtendPremiumComponent,
    options: {}
  },
  data: {
    width: 670
  }
};
