import { EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
var CheckboxGroupComponent = /** @class */ /*@__PURE__*/ (function () {
    function CheckboxGroupComponent() {
        this.clickedItemEmitter = new EventEmitter();
    }
    CheckboxGroupComponent.prototype.onToggle = function (item) {
        var control = this.getControl();
        var updatedValues = control.value.map(function (valueItem) {
            if (valueItem.id === item.id) {
                valueItem.selected = !valueItem.selected;
            }
            return valueItem;
        });
        control.setValue(updatedValues);
        this.clickedItemEmitter.emit(item);
    };
    CheckboxGroupComponent.prototype.writeValue = function (value) {
        this.value = value;
    };
    CheckboxGroupComponent.prototype.registerOnChange = function (fn) { };
    CheckboxGroupComponent.prototype.registerOnTouched = function (fn) { };
    CheckboxGroupComponent.prototype.getControl = function () {
        return this.groupInstance && this.groupInstance.get(this.controlName);
    };
    return CheckboxGroupComponent;
}());
export { CheckboxGroupComponent };
