import { AccessExpiredComponent } from './access-expired.component';

export const accessExpiredConfig = {
  title: '',
  component: {
    name: AccessExpiredComponent,
    options: {}
  },
  data: {
    width: 650
  }
};
