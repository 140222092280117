import { EnvironmentVariables } from 'appy-gas-core';

export const productionVariables: EnvironmentVariables = {
  environment: 'production',
  production: true,
  serverUrl: 'https://ag-prod-wap-api.azurewebsites.net/api',
  gaTrackingId: 'UA-114836633-1',
  userManagementReleaseDate: new Date(2018, 5, 5),
  technicalMaintenance: false
};
