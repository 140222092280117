import { EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { maintenanceFilterSyncConfig } from '../../constants/maintenance-filter-sync.config';
import { MaintenanceFilterTypeEnum } from '../../enums/maintenance-filter-type.enum';
var MaintenanceDashboardFilterComponent = /** @class */ /*@__PURE__*/ (function () {
    function MaintenanceDashboardFilterComponent(fb) {
        this.fb = fb;
        this.toggleFiltersEmitter = new EventEmitter();
        this.filterType = MaintenanceFilterTypeEnum;
    }
    MaintenanceDashboardFilterComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes && changes.maintenancePlanFilters) {
            this.defaultFilters = this.maintenancePlanFilters;
            this.createForm(this.defaultFilters);
            this.filterForm.valueChanges.subscribe(function (formValue) {
                _this.toggleFiltersEmitter.emit(formValue);
            });
        }
    };
    MaintenanceDashboardFilterComponent.prototype.clearFilter = function (event) {
        event.preventDefault();
        this.filterForm.reset({
            ip: this.defaultFilters.ip,
            tso: this.defaultFilters.tso
        });
    };
    MaintenanceDashboardFilterComponent.prototype.syncFilters = function (selectedItems, prop) {
        var filterConfig = maintenanceFilterSyncConfig[prop];
        var syncItems = Array.from(new Set(selectedItems.map(function (i) { return i[filterConfig.syncProperty]; })));
        this.filterForm.controls[filterConfig.fieldName].setValue(this.defaultFilters[filterConfig.fieldName].filter(function (i) { return syncItems.includes(i[filterConfig.property]); }), { onlySelf: true });
    };
    MaintenanceDashboardFilterComponent.prototype.createForm = function (filters) {
        this.filterForm = this.fb.group({
            ip: [filters === null || filters === void 0 ? void 0 : filters.ip],
            tso: [filters === null || filters === void 0 ? void 0 : filters.tso]
        });
    };
    return MaintenanceDashboardFilterComponent;
}());
export { MaintenanceDashboardFilterComponent };
