export var VariableIdsEnum = /*@__PURE__*/ (function (VariableIdsEnum) {
    VariableIdsEnum[VariableIdsEnum["STORAGE_TECHNICAL_ENTRY"] = 7] = "STORAGE_TECHNICAL_ENTRY";
    VariableIdsEnum[VariableIdsEnum["STORAGE_BOOKED_FIRM_ENTRY"] = 171] = "STORAGE_BOOKED_FIRM_ENTRY";
    VariableIdsEnum[VariableIdsEnum["STORAGE_BOOKED_INTERRUPTIBLE_ENTRY"] = 181] = "STORAGE_BOOKED_INTERRUPTIBLE_ENTRY";
    VariableIdsEnum[VariableIdsEnum["STORAGE_TECHNICAL_EXIT"] = 8] = "STORAGE_TECHNICAL_EXIT";
    VariableIdsEnum[VariableIdsEnum["STORAGE_BOOKED_FIRM_EXIT"] = 172] = "STORAGE_BOOKED_FIRM_EXIT";
    VariableIdsEnum[VariableIdsEnum["STORAGE_BOOKED_INTERRUPTIBLE_EXIT"] = 182] = "STORAGE_BOOKED_INTERRUPTIBLE_EXIT";
    VariableIdsEnum[VariableIdsEnum["IP_TECHNICAL_ENTRY"] = 31] = "IP_TECHNICAL_ENTRY";
    VariableIdsEnum[VariableIdsEnum["IP_BOOKED_FIRM_ENTRY"] = 45] = "IP_BOOKED_FIRM_ENTRY";
    VariableIdsEnum[VariableIdsEnum["IP_BOOKED_INTERRUPTIBLE_ENTRY"] = 55] = "IP_BOOKED_INTERRUPTIBLE_ENTRY";
    VariableIdsEnum[VariableIdsEnum["IP_TECHNICAL_EXIT"] = 32] = "IP_TECHNICAL_EXIT";
    VariableIdsEnum[VariableIdsEnum["IP_BOOKED_FIRM_EXIT"] = 46] = "IP_BOOKED_FIRM_EXIT";
    VariableIdsEnum[VariableIdsEnum["IP_BOOKED_INTERRUPTIBLE_EXIT"] = 56] = "IP_BOOKED_INTERRUPTIBLE_EXIT";
    VariableIdsEnum[VariableIdsEnum["REMAINING_CAPACITY"] = 100001] = "REMAINING_CAPACITY";
    VariableIdsEnum[VariableIdsEnum["IP_CLEAN_NOM_ENTRY"] = 108] = "IP_CLEAN_NOM_ENTRY";
    VariableIdsEnum[VariableIdsEnum["IP_CLEAN_NOM_EXIT"] = 109] = "IP_CLEAN_NOM_EXIT";
    VariableIdsEnum[VariableIdsEnum["STORAGE_CLEAN_NOM_ENTRY"] = 77] = "STORAGE_CLEAN_NOM_ENTRY";
    VariableIdsEnum[VariableIdsEnum["STORAGE_CLEAN_NOM_EXIT"] = 78] = "STORAGE_CLEAN_NOM_EXIT";
    return VariableIdsEnum;
})({});
