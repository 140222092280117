import { Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './forgot-password';
import { LoginComponent } from './login';
import { ResetPasswordComponent } from './reset-password';
import { SignupComponent, SignupSuccessComponent } from './signup';
var ɵ0 = {
    title: 'Login'
}, ɵ1 = {
    title: 'Login'
}, ɵ2 = {
    title: 'Signup'
}, ɵ3 = {
    title: 'Signup success'
}, ɵ4 = {
    title: 'Forgot Password'
}, ɵ5 = {
    title: 'Reset Password'
};
var routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            {
                path: '',
                component: LoginComponent,
                data: ɵ0
            },
            {
                path: 'login',
                component: LoginComponent,
                data: ɵ1
            },
            {
                path: 'signup',
                component: SignupComponent,
                data: ɵ2
            },
            {
                path: 'signup/success',
                component: SignupSuccessComponent,
                data: ɵ3
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
                data: ɵ4
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
                data: ɵ5
            }
        ]
    }
];
var AuthRoutingModule = /** @class */ /*@__PURE__*/ (function () {
    function AuthRoutingModule() {
    }
    return AuthRoutingModule;
}());
export { AuthRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
