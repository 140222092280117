import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngxs/store';
import { LocalStorageKeysEnum } from '../../shared/enums/local-storage-keys.enum';
import { UserRoleTransitionService } from '../../shared/services/user-role-transition.service';
import { UserSelectors } from '../../store';
import { welcomePopoverState } from './welcome-popover-state.config';
var WelcomePopoverComponent = /** @class */ /*@__PURE__*/ (function () {
    function WelcomePopoverComponent(activeModal, formBuilder, userRoleTransitionService, store) {
        this.activeModal = activeModal;
        this.formBuilder = formBuilder;
        this.userRoleTransitionService = userRoleTransitionService;
        this.store = store;
        this.showVideo = true;
        this.isExclusiveUser$ = this.store.select(UserSelectors.isExclusiveUser);
    }
    WelcomePopoverComponent.prototype.ngOnInit = function () {
        this.welcomeForm = this.formBuilder.group({
            showWelcomePopup: [false]
        });
    };
    WelcomePopoverComponent.prototype.closeModal = function () {
        this.showVideo = false;
        this.activeModal.close();
        var checkValue = this.welcomeForm.get('showWelcomePopup').value;
        if (!checkValue) {
            window.localStorage.setItem(LocalStorageKeysEnum.DO_NOT_SHOW_NEW_FEATURES_MODAL, welcomePopoverState.IS_REGISTERED);
        }
        else {
            window.localStorage.setItem(LocalStorageKeysEnum.DO_NOT_SHOW_NEW_FEATURES_MODAL, welcomePopoverState.IS_HIDDEN);
        }
    };
    WelcomePopoverComponent.prototype.switchToExclusive = function () {
        this.closeModal();
        this.userRoleTransitionService.switchToExclusive();
    };
    return WelcomePopoverComponent;
}());
export { WelcomePopoverComponent };
