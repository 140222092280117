import { ModalService } from 'appy-gas-core';
var SwitchDeclinedComponent = /** @class */ /*@__PURE__*/ (function () {
    function SwitchDeclinedComponent(modalService) {
        this.modalService = modalService;
    }
    SwitchDeclinedComponent.prototype.hideModal = function () {
        this.modalService.hide();
    };
    return SwitchDeclinedComponent;
}());
export { SwitchDeclinedComponent };
