import { FormGroup } from '@angular/forms';
import closeIcon from 'appy-gas-core/dist/assets/svg/icons/close.svg';
import searchIcon from 'appy-gas-core/dist/assets/svg/icons/search.svg';
var SearchInputComponent = /** @class */ /*@__PURE__*/ (function () {
    function SearchInputComponent() {
        this.closeIcon = closeIcon;
        this.searchIcon = searchIcon;
    }
    SearchInputComponent.prototype.clearInput = function () {
        this.groupInstance.get(this.controlName).setValue('');
    };
    return SearchInputComponent;
}());
export { SearchInputComponent };
