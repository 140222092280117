import { CurrentUserResolverService, EmptyPropGuard } from '../http-auth';
import { LayoutComponent } from '../layout/layout.component';
import { TechnicalMaintenanceGuard } from '../technical-maintenance/technical-maintenance.guard';
/**
 * Provides helper methods to create routes.
 */
var Route = /** @class */ /*@__PURE__*/ (function () {
    function Route() {
    }
    /**
     * Creates routes using the shell component and authentication.
     * @param routes The routes to add.
     */
    Route.withLayout = function (routes) {
        return [
            {
                path: '',
                component: LayoutComponent,
                resolve: {
                    currentUser: CurrentUserResolverService
                },
                canActivate: [TechnicalMaintenanceGuard, EmptyPropGuard],
                children: routes
            }
        ];
    };
    return Route;
}());
export { Route };
