import { MaxSearchesComponent } from './max-searches.component';

export const maxSearchesModalConfig = {
  title: 'You have reached the maximum searches per day as a Classic user',
  component: {
    name: MaxSearchesComponent,
    options: {}
  },
  data: {
    width: 670
  }
};
