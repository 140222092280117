import { __assign } from "tslib";
import { Store } from '@ngxs/store';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalService, Role, SearchService } from 'appy-gas-core';
import availabilityIcon from 'appy-gas-core/dist/assets/svg/icons/header/availability.svg';
import calculatorIcon from 'appy-gas-core/dist/assets/svg/icons/header/calculator.svg';
import homeIcon from 'appy-gas-core/dist/assets/svg/icons/header/dashboard.svg';
import exclusiveProfileIcon from 'appy-gas-core/dist/assets/svg/icons/header/exclusive-profile-new.svg';
import profileIcon from 'appy-gas-core/dist/assets/svg/icons/header/profile.svg';
import signOutIcon from 'appy-gas-core/dist/assets/svg/icons/header/sign-out.svg';
import walletIcon from 'appy-gas-core/dist/assets/svg/icons/header/wallet-icon.svg';
import search from 'appy-gas-core/dist/assets/svg/icons/search.svg';
import switchIcon from 'appy-gas-core/dist/assets/svg/icons/switch-icon.svg';
import { allowedUserRolesForSearchDropDown, headerNavigationLinks, noWalletForContractUsersKeys } from '../../../shared';
import { accessExpiredConfig } from '../../../shared/components/user-transition-modals';
import { generalizedMappedUserRoles } from '../../../shared/constants/generalized-user-roles';
import { HeaderSearchArea } from '../../../shared/enums';
import { UpdateUserRoleResponseTypes } from '../../../shared/enums/update-user-role-response-types.enum';
import { HeaderHelperService } from '../../../shared/services/header-helper.service';
import { UserRoleTransitionService } from '../../../shared/services/user-role-transition.service';
import { CommonEntitiesFacade, FlowsMapSelectors, ProfileFacade, ProfileSelectors, SetUserRole, UpdateUserProfileNewsLetter, UserSelectors } from '../../../store';
import { AuthenticationService } from '../../http-auth';
import { upgradePremiumModalConfig } from '../../upgrade-premium';
var HeaderComponent = /** @class */ /*@__PURE__*/ (function () {
    function HeaderComponent(router, authenticationService, fb, searchService, modalService, store, injector, commonEntitiesFacade, profileFacade, userRoleTransitionService) {
        var _a;
        var _this = this;
        this.router = router;
        this.authenticationService = authenticationService;
        this.fb = fb;
        this.searchService = searchService;
        this.modalService = modalService;
        this.store = store;
        this.injector = injector;
        this.commonEntitiesFacade = commonEntitiesFacade;
        this.profileFacade = profileFacade;
        this.userRoleTransitionService = userRoleTransitionService;
        this.activeSearch = false;
        this.isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
        this.isRestrictedHeaderActions = false;
        this.marketAreasList = [];
        this.suggestions = [];
        this.appiesCount$ = this.store.select(ProfileSelectors.appiesCount);
        this.isContractUser$ = this.store.select(UserSelectors.isContractUser);
        this.isExclusiveUser$ = this.store.select(UserSelectors.isExclusiveUser);
        this.isSwitchableUser$ = this.store.select(UserSelectors.isSwitchableUser);
        this.user$ = this.store.select(UserSelectors.getUser);
        this.componentDestroyed$ = new Subject();
        this.routes = Object.freeze((_a = {},
            _a[HeaderSearchArea.MA] = function (params) {
                _this.router.navigate(['/flows-map']).then(function () {
                    _this.router.navigate(["/market-area-view/"], {
                        queryParams: {
                            firstArea: params.value.id,
                            dateFirstArea: params.selectedDate
                        }
                    });
                });
            },
            _a[HeaderSearchArea.VIP] = function (params) {
                _this.router.navigate(['/flows-map']).then(function () {
                    _this.router.navigate(["/market-area-view/"], {
                        queryParams: {
                            firstArea: params.value.marketAreaId,
                            secondArea: params.value.marketAreaId,
                            pointId: params.value.appyGasId,
                            vipFirstArea: params.value.appyGasId,
                            pointTypeIdFirstArea: params.value.eePointTypeId,
                            vipSecondArea: params.value.appyGasId,
                            pointTypeIdSecondArea: params.value.eePointTypeId,
                            dateFirstArea: params.selectedDate
                        }
                    });
                });
            },
            _a[HeaderSearchArea.PIP] = function (params) {
                _this.router.navigate(['/flows-map']).then(function () {
                    _this.router.navigate(["/market-area-view/"], {
                        queryParams: {
                            firstArea: params.value.marketAreaId,
                            secondArea: params.parent.marketAreaId,
                            pointId: params.parent.appyGasId,
                            vipFirstArea: params.parent.appyGasId,
                            pointTypeIdFirstArea: params.parent.eePointTypeId,
                            vipSecondArea: params.parent.appyGasId,
                            pointTypeIdSecondArea: params.parent.eePointTypeId,
                            dateFirstArea: params.selectedDate
                        }
                    });
                });
            },
            _a));
        this.navigationLinks = headerNavigationLinks;
        this.icons = {
            home: homeIcon,
            calculator: calculatorIcon,
            availability: availabilityIcon,
            signOut: signOutIcon,
            profile: profileIcon,
            exclusiveProfile: exclusiveProfileIcon,
            search: search,
            wallet: walletIcon,
            switchToExclusive: switchIcon
        };
    }
    Object.defineProperty(HeaderComponent.prototype, "newsLetterValue", {
        get: function () {
            return this.store.selectSnapshot(ProfileSelectors.userProfileNewsLetter);
        },
        enumerable: false,
        configurable: true
    });
    HeaderComponent.prototype.ngOnInit = function () {
        this.getProfileInformation();
        this.getGeneralData();
        this.authSubscriptions();
        this.searchForm = this.fb.group({
            search: ['']
        });
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    };
    HeaderComponent.prototype.onSubmit = function (form) {
        var url = "/market-area-view/" + form.value.search.id;
        this.router.navigate([url]);
    };
    HeaderComponent.prototype.suggest = function (event, skip) {
        var suggestions = this.marketAreasList.filter(function (x) { return (x === null || x === void 0 ? void 0 : x.name.toLowerCase().includes(event.query.toLowerCase())) && x.name !== skip; });
        this.suggestions = [];
        this.filterAndUpdateSuggestions(suggestions);
    };
    HeaderComponent.prototype.focus = function () {
        var _this = this;
        this.authenticationService.allowRoles(allowedUserRolesForSearchDropDown).then(function (authorize) {
            if (authorize) {
                if (!_this.marketAreasList.length) {
                    _this.searchService.getMarketAreasForSearch().subscribe(function (data) {
                        _this.marketAreasList = data.filter(function (searchItem) { return !_this.userRoleTransitionService.getRestrictedMAFeatureByMAId(searchItem.marketAreaId); });
                        _this.mapMarketAreaListNames();
                        _this.filterAndUpdateSuggestions(_this.marketAreasList.filter(function (x) { return (x === null || x === void 0 ? void 0 : x.name) !== ''; }));
                    });
                }
                _this.activeSearch = true;
            }
            else {
                _this.modalService.show(_this.injector, upgradePremiumModalConfig);
                return false;
            }
        });
    };
    HeaderComponent.prototype.search = function (event, value) {
        var parent = this.marketAreasList.find(function (item) { return item.id === value.parentPointId; });
        var selectedDate = this.store.selectSnapshot(FlowsMapSelectors.selectedDate);
        this.routes[value.resultType]({ value: value, parent: parent, selectedDate: selectedDate });
        this.searchForm.reset();
    };
    HeaderComponent.prototype.switchToExclusive = function () {
        this.userRoleTransitionService.switchToExclusive();
    };
    HeaderComponent.prototype.showExpiredModal = function () {
        var _this = this;
        var config = __assign(__assign({}, accessExpiredConfig), { component: __assign(__assign({}, accessExpiredConfig.component), { options: __assign(__assign({}, accessExpiredConfig.component.options), { newsLetterValue: this.newsLetterValue, syncNewsLetterSubscription: function (newsLetterValue) { return _this.syncNewsLetterSubscription(newsLetterValue); } }) }) });
        this.modalService.show(this.injector, config);
    };
    HeaderComponent.prototype.navigateToWallet = function () {
        this.router.navigateByUrl('profile/wallet');
    };
    HeaderComponent.prototype.mapMarketAreaListNames = function () {
        var _this = this;
        this.marketAreasList.forEach(function (point) {
            if (point.resultType === HeaderSearchArea.VIP) {
                var marketArea = _this.marketAreasList.find(function (item) { return point.marketAreaId === item.id; });
                point.name = point.name + " (" + marketArea.name + ")";
            }
            if (point.operatorName) {
                point.name = point.name + " (" + point.operatorName + ")";
            }
        });
    };
    HeaderComponent.prototype.syncNewsLetterSubscription = function (newsLetterValue) {
        this.store.dispatch(new UpdateUserProfileNewsLetter(newsLetterValue));
    };
    HeaderComponent.prototype.authSubscriptions = function () {
        var _this = this;
        this.authenticationService.roleExpDays.subscribe(function (expDays) { return (_this.licenseExpire = expDays); });
        this.authenticationService.role
            .pipe(tap(function (role) { return _this.store.dispatch(new SetUserRole(role)); }))
            .subscribe(function (role) {
            _this.isRestrictedHeaderActions = Role.Expired === role;
            _this.currentRole = generalizedMappedUserRoles.get(role);
        });
    };
    HeaderComponent.prototype.getGeneralData = function () {
        var _this = this;
        this.isContractUser$
            .pipe(filter(function (val) { return !!val; }), takeUntil(this.componentDestroyed$))
            .subscribe(function (val) {
            _this.isContractUser = val;
            var appiesCountAvailable = _this.store.selectSnapshot(ProfileSelectors.appiesCount);
            var walletBalanceStatus = _this.store.selectSnapshot(ProfileSelectors.walletBalanceStatus);
            if (noWalletForContractUsersKeys.includes(walletBalanceStatus)) {
                return;
            }
            if (!isNumber(appiesCountAvailable) && walletBalanceStatus !== UpdateUserRoleResponseTypes.BALANCE_NOT_FOUND) {
                _this.profileFacade.getWalletInformation();
            }
        });
    };
    HeaderComponent.prototype.getProfileInformation = function () {
        var _this = this;
        this.user$
            .pipe(filter(function (user) { return !!user; }), distinctUntilChanged(function (prev, curr) { return isEqual(prev, curr); }), tap(function (user) {
            _this.profileFacade.getUserPortfolioPoints(user.id);
            _this.commonEntitiesFacade.getEeVirtualPoints(new Date().getTime());
        }), takeUntil(this.componentDestroyed$))
            .subscribe();
    };
    HeaderComponent.prototype.filterAndUpdateSuggestions = function (suggestionsData) {
        var selectedDate = this.store.selectSnapshot(FlowsMapSelectors.selectedDate);
        this.suggestions = HeaderHelperService.filterSearchResultByGermanyMergeState(suggestionsData, selectedDate);
    };
    return HeaderComponent;
}());
export { HeaderComponent };
