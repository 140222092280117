import { of } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { RequestCache } from '../request-cache.service';
/**
 * If request is cachable (e.g., package search) and
 * response is in cache return the cached response as observable.
 * If has 'x-refresh' header that is true,
 * then also re-run the package search, using response from next(),
 * returning an observable that emits the cached response first.
 *
 * If not in cache or not cachable,
 * pass request through to next()
 */
var CachingInterceptor = /** @class */ /*@__PURE__*/ (function () {
    function CachingInterceptor(cache) {
        this.cache = cache;
    }
    CachingInterceptor.checkIfUrlIsCacheable = function (headerMetaData) {
        return !!(headerMetaData && headerMetaData.cache);
    };
    /** Is this request cachable? */
    CachingInterceptor.isCacheable = function (req, headerMetaData) {
        // Only GET requests are cachable
        return req.method === 'GET' && CachingInterceptor.checkIfUrlIsCacheable(headerMetaData);
    };
    CachingInterceptor.prototype.intercept = function (req, next) {
        var headerMetaData = JSON.parse(req.headers.get('X-META-DATA'));
        // continue if not cachable.
        if (!CachingInterceptor.isCacheable(req, headerMetaData)) {
            return next.handle(req);
        }
        var cachedResponse = this.cache.get(req, headerMetaData);
        // cache-then-refresh
        // The cache-then-refresh option is triggered by the presence of a custom refreshCache header meta data.
        if (headerMetaData && headerMetaData.refreshCache) {
            var results$ = sendRequest(req, next, this.cache, headerMetaData);
            return cachedResponse ? results$.pipe(startWith(cachedResponse)) : results$;
        }
        // cache-or-fetch
        return cachedResponse ? of(cachedResponse) : sendRequest(req, next, this.cache, headerMetaData);
    };
    return CachingInterceptor;
}());
export { CachingInterceptor };
/**
 * Get server response observable by sending request to `next()`.
 * Will add the response to the cache on the way out.
 */
function sendRequest(req, next, cache, headerMetaData) {
    return next.handle(req).pipe(tap(function (event) {
        if (event instanceof HttpResponse) {
            cache.put(req, event, headerMetaData); // Update the cache.
        }
    }));
}
