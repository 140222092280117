import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { getMetadataHeaders } from '../core/http-auth/headers.helper';
import { ProfileHelperService } from '../shared/services/profile-helper.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProfileService = /** @class */ /*@__PURE__*/ (function () {
    function ProfileService(http) {
        this.http = http;
    }
    ProfileService.prototype.getPortfolioInformation = function () {
        return this.http.get('/preferences/portfolio');
    };
    ProfileService.prototype.getUserPortfolioPoints = function (userId) {
        return (this.http.get("/preferences/" + userId + "/portfolio", { headers: getMetadataHeaders({ auth: true }) }));
    };
    ProfileService.prototype.saveUserPortfolioPoints = function (userId, pointIds) {
        // TODO remove when super points with two operators removed
        var parsedWithExceptionsPoints = ProfileHelperService.parseSuperPointExceptionIds(pointIds);
        return (this.http
            .put("/preferences/" + userId + "/portfolio", { pointIds: parsedWithExceptionsPoints }, { headers: getMetadataHeaders({ auth: true }) })
            .pipe(map(function (ids) { return ProfileHelperService.addSuperPointExceptionIds(ids); })));
    };
    ProfileService.prototype.getWalletInformation = function () {
        return (this.http.get("/accounts/wallet", { headers: getMetadataHeaders({ auth: true }) }));
    };
    ProfileService.prototype.getUserBadges = function (userId) {
        return (this.http.get("/badges/" + userId, { headers: getMetadataHeaders({ auth: true }) }));
    };
    ProfileService.prototype.getAllBadges = function () {
        return this.http.get("/badges", { headers: getMetadataHeaders({ auth: true }) });
    };
    ProfileService.ɵprov = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.HttpClient)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
