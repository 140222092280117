import { virtualPointIdsAfterGermanyMerge, virtualPointIdsBeforeGermanyMerge } from '../constants/app-exceptions';
var MarketAreaViewHelperService = /** @class */ /*@__PURE__*/ (function () {
    function MarketAreaViewHelperService() {
    }
    MarketAreaViewHelperService.filterVirtualPoints = function (isMergedGermanyMAState, virtualPoints) {
        return isMergedGermanyMAState
            ? virtualPoints.filter(function (point) { return !virtualPointIdsBeforeGermanyMerge.includes(point.appyGasId); })
            : virtualPoints.filter(function (point) { return !virtualPointIdsAfterGermanyMerge.includes(point.appyGasId); });
    };
    return MarketAreaViewHelperService;
}());
export { MarketAreaViewHelperService };
